/* eslint-disable no-console */

import {unregister} from 'register-service-worker';
//删除本地缓存
caches.keys().then( cacheNames => {
  cacheNames.forEach(value => {
    console.log('delete', value)
    caches.delete(value);
  });
  console.log('delete-cacheNames', cacheNames)
  caches.delete(cacheNames);
})
console.log('Service worker has been registered.')
//卸载pwa
unregister();
console.log('unregister-serviceWorker')
