import constants from "./constants";
import utils from "./utils";
const { v4: uuid } = require('uuid');
let textProcess = {};
import vuex from "../store/index"
import * as request from "../http/httpRequest";

/**
 * 根据地址及视频状态,获取userJson。
 * @param captionJsonRemotePath 服务器端userJson地址。
 * @param captionSrcJsonRemotePath 原始识别结果地址。
 * @param recordId 分享视频id。
 * @param msDuration 视频时间。
 * @param clipInfoArr 裁剪信息数组。
 * @param lang 语言，默认为中文。
 */
textProcess.getShareJson = async function (captionJsonRemotePath, captionSrcJsonRemotePath, recordId, msDuration, clipInfoArr, lang) {
    let videoLang = lang ? lang : constants.LANG_ZH;
    //定义一个初始的json
    let userJson = {};
    //根据情况判断
    if (captionJsonRemotePath) {
        //若服务端json地址存在，按此地址获取userJson，不考虑简单裁剪的情况。
        let userJsonByServe = await textProcess.getUserJson(captionJsonRemotePath);
        userJson = userJsonByServe.userJson
    } else if (captionSrcJsonRemotePath) {
        //若服务端json地址不存在，但原始地址存在;根据原始地址获取json文件，需要考虑简单裁剪信息。
        let json = await textProcess.getSrcJson(captionSrcJsonRemotePath);
        //保存原始结果，打轴时需要
        textProcess.splitCaptionSrcJsonContent(json, true)
        //处理原始识别结果。
        let userSrcJson = textProcess.processResult(json, msDuration, videoLang);
        if (clipInfoArr.toString().length !== 0) {
            //处理简单裁剪信息
            userSrcJson = textProcess.clipInfoArrText(clipInfoArr, userSrcJson)
        }
        userJson = userSrcJson;
    }
    return Promise.resolve({userJson})
}
//创建段落
textProcess.createParagraph = () => {
    return {
        text:[],
        content:'',
        isHide:false,
        isEditor:false,
        id:uuid()
    }
}
//处理原始识别结果
textProcess.processResult = (json, msDuration, editorLang) => {
    let time = 0;
    let text = [];
    //识别结果为空默认创建一个段落
    if (json.results.length === 0) {
        json.results.push({results:[]});
    }
    for (let i = 0; i < json.results.length; i++) {
        //创建一个段落对象，段落对象中包含词
        let paragraphPointObj = textProcess.createParagraph();
        let results = json.results[i];
        //结果为空默认添加一个占位点
        if (results.length === 0) {
            let pointObj = {
                bt:0,
                et:constants.INTERVALS_TIME,
                text:"",
                type:constants.SENTENCE_POINT,
            }
            paragraphPointObj.text.push(pointObj);
        } else {
            for (let i = 0; i < results.length; i++) {
                let words = results[i].words;
                for (let j = 0; j < words.length; j++) {
                    let isEditorNullParagraph = false;
                    if (words.length === 1 && words[0].type === constants.SENTENCE_LINE_NULL) {
                        words[0].bt =  words[0].et
                        isEditorNullParagraph = true;
                    }
                    if (!isEditorNullParagraph) {
                        words[j].type = constants.SENTENCE_TEXT;
                    }
                    //添加占位圆点
                    let intervals = words[j].bt - time;
                    if (intervals >= constants.WORD_INTERVALS_TIME || (j === 0 &&  words[j].bt !== time)) {
                        let num = Math.ceil(intervals / constants.INTERVALS_TIME);
                        let preEndTime = time;
                        for (let k = 0; k < num; k++) {
                            let pointObj = {};
                            if (k === num - 1) {
                                pointObj = {
                                    bt:preEndTime,
                                    et:words[j].bt,
                                    text:"",
                                    type:constants.SENTENCE_POINT,
                                }
                            } else {
                                pointObj = {
                                    bt:preEndTime,
                                    et:preEndTime + constants.INTERVALS_TIME,
                                    text:"",
                                    type:constants.SENTENCE_POINT,
                                }
                                preEndTime = preEndTime + constants.INTERVALS_TIME;
                            }
                            paragraphPointObj.text.push(pointObj);
                        }
                    }
                    time = words[j].et;
                    if (!isEditorNullParagraph) {
                        //中文将多个字符拆成一个字
                        if (editorLang === constants.LANG_ZH) {
                            if (words[j].text.length <= 1) {
                                paragraphPointObj.text.push(words[j]);
                            } else {
                                let bt = words[j].bt;
                                let et = words[j].et;
                                let splitArray = words[j].text.split('');
                                splitArray.forEach(item => {
                                    paragraphPointObj.text.push({
                                        bt:bt,
                                        et:et,
                                        type:constants.SENTENCE_TEXT,
                                        text:item
                                    })
                                })
                            }
                        } else {
                            words[j].text = words[j].text.trim() + ' ';
                            paragraphPointObj.text.push(words[j]);
                        }
                    }
                }
            }
        }
        if (i === json.results.length - 1) {
            //在结尾处添加时间占位
            let textEndTime = 0
            if (paragraphPointObj.text.length !== 0) {
                textEndTime = paragraphPointObj.text[paragraphPointObj.text.length - 1].et;
            }
            let num = Math.ceil((msDuration - textEndTime) / constants.INTERVALS_TIME);
            let preEndTime = textEndTime;
            for (let i = 0; i < num; i++) {
                let pointObj = {};
                if (i === num - 1) {
                    pointObj = {
                        bt:preEndTime,
                        et:msDuration,
                        text:"",
                        type:constants.SENTENCE_POINT,
                    }
                } else {
                    pointObj = {
                        bt:preEndTime,
                        et:preEndTime + constants.INTERVALS_TIME,
                        text:"",
                        type:constants.SENTENCE_POINT,
                    }
                    preEndTime = preEndTime + constants.INTERVALS_TIME;
                }
                paragraphPointObj.text.push(pointObj);
            }
        }
        text.push(paragraphPointObj);
    }
    return text;
}

textProcess.clipText = (cutVideoBt, cutVideoEt, text, msDuration) => {
    let preCut = {
        id:uuid(),
        bt:0,
        et:cutVideoBt,
        text:'[...]',
        deleteContent:[],
        type:constants.SENTENCE_DELETE,
    };
    let lastCut = {
        id:uuid(),
        bt:cutVideoEt,
        et:msDuration,
        text:'[...]',
        deleteContent:[],
        type:constants.SENTENCE_DELETE,
    };
    if (cutVideoBt !== 0) {
        for (let i = 0; i < text.length; i++) {
            let paragraph = text[i].text;
            for (let j = 0; j < paragraph.length; j++) {
                if (paragraph[j].bt <= cutVideoBt) {
                    if (paragraph[j].type === constants.SENTENCE_DELETE) {
                        let addIndex = j + 1;
                        paragraph[j].deleteContent.forEach((item, index) => {
                            // preCut.deleteContent.push(item);
                            text[i].text.splice(addIndex + index, 0, item)
                        })
                    } else {
                        preCut.deleteContent.push(paragraph[j]);
                    }
                    paragraph.splice(j, 1);
                    j = j - 1;
                }
            }
            if (paragraph.length === 0) {
                text.splice(i, 1);
                i = i - 1;
            }
        }
        for (let i = 0; i < text.length; i++) {
            if (text[i].text.length === 0) {
                text.splice(i, 1);
                i = i - 1;
            }
        }
        text[0].text.unshift(preCut);
    }
    if (cutVideoEt !== msDuration) {
        for (let i = 0; i < text.length; i++) {
            let paragraph = text[i].text;
            for (let j = 0; j < paragraph.length; j++) {
                if (paragraph[j].et >= cutVideoEt) {
                    if (paragraph[j].type === constants.SENTENCE_DELETE) {
                        let addIndex = j + 1;
                        paragraph[j].deleteContent.forEach((item, index) => {
                            // lastCut.deleteContent.push(item);
                            text[i].text.splice(addIndex + index, 0, item)
                        })
                    } else {
                        lastCut.deleteContent.push(paragraph[j]);
                    }
                    paragraph.splice(j, 1);
                    j = j - 1;
                }
            }
            if (paragraph === 0) {
                text.splice(i, 1);
                i = i - 1;
            }
        }
        for (let i = 0; i < text.length; i++) {
            if (text[i].text.length === 0) {
                text.splice(i, 1);
                i = i - 1;
            }
        }
        let textLength = text.length;
        if (textLength !== 0) {
            text[textLength - 1].text.push(lastCut);
        }
    }
    return text;
}
/**
 * 根据裁剪信息数组，解析文本为裁剪完成后的文本
 */
textProcess.clipInfoArrText = (clipInfoArr, text) => {
    let content = JSON.parse(JSON.stringify(text));
    for (let i = 0; i < content.length; i++) {
        let paragraph = content[i];
        for (let j = 0; j < paragraph.text.length; j++) {
            if (paragraph.text[j].type === constants.SENTENCE_DELETE) {
                paragraph.text[j].deleteContent.forEach((item, index) => {
                    paragraph.text.splice(j + 1 + index, 0, item);
                })
                paragraph.text.splice(j, 1);
                j--;
            } else if (paragraph.text[j].type === constants.SENTENCE_PARAGRAPH_END) {
                paragraph.text.splice(j, 1);
                j--;
            }
        }
        content[i] = paragraph;
    }

    for (let j = 0; j < content.length; j++) {
        let paragraph = content[j];
        let deleteContent = [];
        for (let k = 0; k < paragraph.text.length; k++) {
            let word = paragraph.text[k];
            if (word.type !== constants.SENTENCE_DELETE) {
                let isExist = clipInfoArr.findIndex(item => {
                    return word.bt >= item.bt && word.et <= item.et;
                })
                if (isExist !== -1) {
                    if (deleteContent.length !== 0) {
                        let deleteDom = textProcess.createDeletePoint(deleteContent[0].bt, deleteContent[deleteContent.length - 1].et);
                        deleteDom.deleteContent = deleteContent;
                        paragraph.text.splice(k, 0, deleteDom);
                        deleteContent = [];
                    }
                } else {
                    //删除文字
                    deleteContent.push(word);
                    if (k === paragraph.text.length - 1) {
                        paragraph.text.splice(k, 1);
                        let deleteDom = textProcess.createDeletePoint(deleteContent[0].bt, deleteContent[deleteContent.length - 1].et);
                        deleteDom.deleteContent = deleteContent;
                        paragraph.text.splice(k, 0, deleteDom);
                        break;
                    } else {
                        paragraph.text.splice(k, 1);
                        k--;
                    }
                }
            }
        }
        content[j] = paragraph;
    }
    return content;
}
//获取快照json
textProcess.getServiceJson = (text, recordId, styleInfo) => {
    let bt = '';
    let results = [];
    let cut = []
    let reg = /\?|,|。|，|！|!|？/;
    let words = [];
    let seq = 1;
    let cutTime = 0;
    let afterClipText = [];
    if (text) {
        text.forEach((item) => {
            let sentences = '';
            let textPoint = [];
            let isEditorNull = item.isEditorNull;
            item.text.forEach((text) => {
                if (text.type === constants.SENTENCE_TEXT) {
                    let saveText  = JSON.parse(JSON.stringify(text))
                    saveText.bt = saveText.bt - cutTime;
                    saveText.et = saveText.et - cutTime;
                    textPoint.push({
                        bt:saveText.bt,
                        et:saveText.et,
                        text:saveText.text
                    })
                } else if (text.type === constants.SENTENCE_DELETE){
                    cutTime = cutTime + (text.et - text.bt);
                    // 存在两段删除的时间段重合或是包含的情况，如有此情况，裁剪时间 cutTime 需减去重合或包含的时间段时间
                    cut.forEach(item => {
                        if (item.bt >= text.bt && item.et <= text.et) {
                            cutTime -= (item.et - item.bt);
                        } else if (item.bt <= text.bt && item.et >= text.et) {
                            cutTime -= (text.et - text.bt);
                        }
                    })
                    cut.push({bt:text.bt, et:text.et});
                }
            })
            if (isEditorNull && textPoint.length === 0) {
                afterClipText.push([
                    {
                        type:constants.SENTENCE_LINE_NULL,
                        bt:item.bt - cutTime,
                        et:item.et - cutTime,
                        text:'',
                    }
                ])
            } else {
                afterClipText.push(textPoint);
            }
            textPoint.forEach((item, index) => {
                words.push({
                    bt: utils.msToSrt(item.bt),
                    text: item.text,
                    et: utils.msToSrt(item.et)
                })
                if (reg.test(item.text) || index === textPoint.length - 1) {
                    sentences = sentences + item.text;
                    //去掉结尾符号
                    if (reg.test(sentences.substring(sentences.length - 1, sentences.length))) {
                        sentences = sentences.substr(0, sentences.length - 1);
                    }
                    if (sentences !== '') {
                        let obj = {
                            bt:utils.msToSrt(bt),
                            et:utils.msToSrt(item.et),
                            seq:seq,
                            words:words,
                            s:sentences,
                        }
                        seq = seq + 1;
                        results.push(obj);
                    }
                    words = [];
                    bt = '';
                    sentences = '';
                } else {
                    if (sentences === '') {
                        bt = item.bt;
                    }
                    sentences = sentences + item.text;
                }
            })
        })
    }
    // 裁剪片段合并，如果裁剪时间线有重合或者是小于 1000 毫秒（最小剪辑时间为 1000 毫秒），则合并
    for (let i = 0; i < cut.length; i++) {
        if (i !== cut.length - 1) {
            if (cut[i].et === cut[i + 1].bt || cut[i + 1].bt - cut[i].et < 1000) {
                cut[i].et = cut[i + 1].et
                cut.splice(i + 1, 1);
                i--
            }
        }
    }
    let saveJson = {
        cut:cut,
        version:constants.USERJSON_VERSION,
        recordId:recordId,
        style:"",
        afterClipText:afterClipText
    };
    if (styleInfo) {
        saveJson.style = setDefaultCaptionStyle(styleInfo.fontSize, styleInfo.fontSizeOnVideoWidth, styleInfo.editorLang);
    }
    return saveJson
}
//设置默认字幕样式
function setDefaultCaptionStyle(fontSize, fontSizeOnVideoWidth, editorLang){
    let style = {
        "fontPx": fontSize,
        "fontSize": fontSize + 8,
        "fontFamilyName": "FandolHei",
        "fontColor": "#FFFFFF",
        "fontFamilyId": "1000",
        "fontSizeOnVideoWidth": fontSizeOnVideoWidth,
        "marginV": 0.08,
        "background":true,
        "backgroundColor":"rgba(0, 0, 0, 0.75)",
        "backgroundPaddingH":5,
        "backgroundPaddingV":5,
        "borderSize": 0,
        "borderColor": "#000000",
        "spacing":editorLang === constants.LANG_ZH ? 1:0
    }
    return style
}
//获取原始结果
textProcess.getSrcJson = async (url) => {
    return new Promise((resolve, reject) => {
        request.getFile(url).then(value => {
            if (value.afterClipText) {
                value.results = [];
                for (let i = 0; i < value.afterClipText.length; i++) {
                    let paragraph = value.afterClipText[i];
                    if (paragraph.length > 0) {
                        let array = []
                        array.push({words:paragraph})
                        value.results.push(array);
                    }
                }
            }
            resolve(value);
        }).catch(e => {
            console.log(e);
            reject(null);
        })
    })
}
//获取服务器存储的userJson
textProcess.getUserJson = async (url) => {
    return new Promise((resolve, reject) => {
        request.getFile(url).then(value => {
            resolve(value);
        }).catch(e => {
            console.log(e);
            reject(null);
        })
    })
}
//将原始结果格式转换为字幕打轴的格式
textProcess.splitCaptionSrcJsonContent = function (captionSrcJsonContent, isChangeSrtToMs){
    let srcOriginalStr = '';
    let srcOriginalResult = [];
    for (let k = 0; k < captionSrcJsonContent.results.length; k++) {
        let results = captionSrcJsonContent.results[k]
        for (let i = 0; i < results.length; i++) {
            let words = results[i].words;
            let sentenceId = uuid();
            for (let j = 0; j < words.length; j++) {
                if (isChangeSrtToMs) {
                    words[j].bt = utils.srtToMs(words[j].bt);
                    words[j].et = utils.srtToMs(words[j].et);
                }
                words[j].sentenceId = sentenceId;
                srcOriginalResult.push(words[j])
                srcOriginalStr = srcOriginalStr + words[j].text;
            }
        }
    }
    return {srcOriginalStr, srcOriginalResult}
}
//添加段位节点
textProcess.addParagraphEndPoint = function (userJson, isDeletePointClipParagraph = true){
    let wordIndex = 0;
    let wordIndexPureText = 0;
    let paragraphIndex = 0;
    if (isDeletePointClipParagraph) {
        //按删除节点分段
        for (let i = 0; i < userJson.length; i++) {
            let text = userJson[i].text;
            for (let j = 0; j < text.length; j++) {
                if (text[j].type === constants.SENTENCE_DELETE) {
                    let newParagraph = textProcess.createParagraph();
                    newParagraph.text.push(text[j]);
                    let preText = text.slice(0, j);
                    let lastText = text.slice(j + 1, text.length);
                    if (preText.length === 0) {
                        userJson[i] = newParagraph;
                    } else {
                        userJson[i].text = preText;
                        i++
                        userJson.splice(i, 0, newParagraph)
                    }
                    if (lastText.length !== 0) {
                        let lastNewParagraph = textProcess.createParagraph();
                        lastNewParagraph.text = lastText;
                        userJson.splice(i + 1, 0, lastNewParagraph)
                    }
                    break
                }
            }
        }
    }
    for (let i = 0; i < userJson.length; i++) {
        let text = userJson[i].text;
        if (text.length !== 0) {
            userJson[i].content = '';
            userJson[i].contentPureText = ''; //纯文本
            userJson[i].pureText = []; //纯文本数组
            userJson[i].isExistContent = false //段落是否有内容
            let paragraphBt = undefined;
            let paragraphEt = undefined;
            if (!userJson[i].isEditorNull) {
                userJson[i].isEditorNull = false //是否是用户手动编辑为空
            } else {
                userJson[i].isExistContent = true;
            }
            paragraphBt = text[0].bt;
            paragraphEt = text[text.length - 1].et;
            for (let j = 0; j < text.length; j++) {
                if (text[j].type === constants.SENTENCE_PARAGRAPH_END) {
                    userJson[i].text.splice(j, 1);
                    j = j - 1;
                } else if (text[j].type === constants.SENTENCE_TEXT) {
                    userJson[i].isExistContent = true;
                }
            }
            //确保所有段落的时间都进行更新
            userJson[i].bt = paragraphBt;
            userJson[i].et = paragraphEt;
            //有内容的段落节点加标记
            if (userJson[i].isExistContent) {
                userJson[i].index = paragraphIndex;
                paragraphIndex++
            }
            //添加段尾占位节点
            if (userJson[i].text.length === 0) {
                userJson.splice(i, 1);
                i = i - 1
            } else {
                userJson[i].text.push({
                    bt:userJson[i].text[userJson[i].text.length - 1].et,
                    et:userJson[i].text[userJson[i].text.length - 1].et,
                    type:constants.SENTENCE_PARAGRAPH_END,
                    text:''
                })
            }
            let point = '';
            //组合段落内容，标记文字编号
            for (let j = 0; j < text.length; j++) {
                text[j].index = wordIndex;
                if (text[j].type === constants.SENTENCE_POINT) {
                    point = point + '•';
                    //段尾
                    if (j === text.length - 1) {
                        point = '<span class="hide-point">' + point + '</span>'
                        userJson[i].content = userJson[i].content + point
                        point = '';
                    }
                } else {
                    if (text[j].type === constants.SENTENCE_TEXT || text[j].type === constants.SENTENCE_PARAGRAPH_END) {
                        if (text[j].type === constants.SENTENCE_TEXT) {
                            userJson[i].isEditorNull = false
                        }
                        if (userJson[i].isExistContent) {
                            let pureTextObj = JSON.parse(JSON.stringify(text[j]));
                            pureTextObj.index = wordIndexPureText
                            userJson[i].pureText.push(pureTextObj)
                            wordIndexPureText++
                        }
                        if (text[j].type === constants.SENTENCE_TEXT) {
                            userJson[i].contentPureText = userJson[i].contentPureText + text[j].text;
                        }
                    }
                    if (point !== '') {
                        point = '<span class="hide-point">' + point + '</span>'
                        let addText = text[j].text
                        if (text[j].type === constants.SENTENCE_DELETE) {
                            addText = '<span class="hide-delete">' + addText + '</span>'
                        }
                        userJson[i].content = userJson[i].content + point + addText
                        point = '';
                    } else {
                        let addText = text[j].text
                        if (text[j].type === constants.SENTENCE_DELETE) {
                            addText = '<span class="hide-delete">' + addText + '</span>'
                        }
                        userJson[i].content = userJson[i].content + addText
                    }
                }
                wordIndex++
            }
        } else {
            userJson.splice(i, 1);
            i--
        }
    }
    return userJson
}
//mousedown 获取最近文字底部编号
textProcess.getCurrentWordDownLineIndex = (className, cursorIndex) => {
    let doms = document.getElementsByClassName(className);
    let currentDom = doms.namedItem(className + cursorIndex);
    let currentCursorTop = currentDom.offsetTop;
    let currentCursorLeft = currentDom.offsetLeft;
    let currentCursorHeight = currentDom.clientHeight;
    let currentParagraphTop = currentDom.offsetParent.offsetTop
    let targetLocationTop = currentCursorTop + currentParagraphTop + currentCursorHeight;
    let targetArray = [];
    for (let i = 0; i < doms.length; i++) {
        let domTop = doms[i].offsetTop;
        let domParagraphTop = doms[i].offsetParent.offsetTop;
        if (domParagraphTop >= currentParagraphTop) {
            if (domTop + domParagraphTop >= targetLocationTop) {
                if (targetArray.length === 0) {
                    targetArray.push({
                        top:domTop + domParagraphTop,
                        cursorIndex:Number(doms[i].getAttribute('index')),
                        left:doms[i].offsetLeft
                    })
                } else {
                    if (domTop + domParagraphTop > targetArray[0].top) {
                        break
                    } else {
                        targetArray.push({
                            top:domTop + domParagraphTop,
                            cursorIndex:Number(doms[i].getAttribute('index')),
                            left:doms[i].offsetLeft
                        })
                    }
                }
            }
        }
    }
    if (targetArray.length === 0) {
        return null
    } else {
        let targetInfo = null;
        for (let i = 0; i < targetArray.length; i ++) {
            if (targetArray[i].left <= currentCursorLeft) {
                targetInfo = targetArray[i]
            }
        }
        return targetInfo === null ? targetArray[0] : targetInfo
    }

}
//mousedown 获取最近文字顶部编号
textProcess.getCurrentWordUpLineIndex = (className, cursorIndex) => {
    let doms = document.getElementsByClassName(className);
    let currentDom = doms.namedItem(className + cursorIndex);
    let currentCursorTop = currentDom.offsetTop;
    let currentCursorLeft = currentDom.offsetLeft;
    let currentCursorHeight = currentDom.clientHeight;
    let currentParagraphTop = currentDom.offsetParent.offsetTop
    let targetLocationTop = currentCursorTop + currentParagraphTop - currentCursorHeight;
    let targetArray = [];
    for (let i = 0; i < doms.length; i++) {
        let domTop = doms[i].offsetTop;
        let domParagraphTop = doms[i].offsetParent.offsetTop;
        if (domTop + domParagraphTop <= targetLocationTop) {
            targetArray.push({
                top:domTop + domParagraphTop,
                cursorIndex:Number(doms[i].getAttribute('index')),
                left:doms[i].offsetLeft
            })
        } else {
            break
        }
    }
    if (targetArray.length === 0) {
        return null
    } else {
        let targetInfo = null;
        for (let i = 0; i < targetArray.length; i ++) {
            if (targetArray[i].left <= currentCursorLeft) {
                targetInfo = targetArray[i]
            }
        }
        return targetInfo === null ? targetArray[0] : targetInfo
    }
}
//组合句子
textProcess.getSentence = (userJson) => {
    //按标点分割
    let reg = /\?|,|\.|。|，|！|!|？/;
    let sentenceArray = [];
    for (let number = 0; number < userJson.length; number++) {
        let bt = '';
        let textPoint = [];
        let sentences = '';
        let item = userJson[number];
        item.text.forEach((text) => {
            if (text.type === constants.SENTENCE_TEXT) {
                textPoint.push(text)
            }
        })
        textPoint.forEach((item, index) => {
            if (sentences === '') {
                bt = item.bt;
            }
            let isSplit = false;
            if (reg.test(item.text)) {
                if (textPoint[index - 1] && textPoint[index + 1]) {
                    let preStr = textPoint[index - 1].text.substr(textPoint[index - 1].text.length - 1, 1);
                    let lastStr = textPoint[index + 1].text.substr(0, 1);
                    if (utils.isNumber(preStr) && utils.isNumber(lastStr)) {
                        isSplit = false;
                    } else {
                        isSplit = true;
                    }
                } else {
                    isSplit = true
                }
            }
            if (isSplit || index === textPoint.length - 1) {
                sentences = sentences + item.text;
                //去掉结尾符号
                sentences = sentences.trim();
                if (reg.test(sentences.substring(sentences.length - 1, sentences.length))) {
                    sentences = sentences.substr(0, sentences.length - 1);
                }
                if (sentences !== '') {
                    let obj = {
                        bt:bt,
                        et:item.et,
                        text:sentences,
                    }
                    sentenceArray.push(obj);
                }
                bt = '';
                sentences = '';
            } else {
                sentences = sentences + item.text;
            }
        })
    }
    return sentenceArray
}
textProcess.getExportVideoJson = (text, deleteClip, recordId, version) => {
    let bt = '';
    let results = [];
    let cut = []
    let reg = /\?|,|\.|。|，|！|!|？/;
    let words = [];
    let seq = 1;
    let cutTime = 0;
    text.forEach((item) => {
        let sentences = '';
        let textPoint = [];
        item.text.forEach((text) => {
            if (text.type === constants.SENTENCE_TEXT) {
                let saveText  = JSON.parse(JSON.stringify(text))
                saveText.bt = saveText.bt - cutTime;
                saveText.et = saveText.et - cutTime;
                textPoint.push(saveText)
            } else if (text.type === constants.SENTENCE_DELETE){
                cutTime = cutTime + (text.et - text.bt);
                // 存在两段删除的时间段重合或是包含的情况，如有此情况，裁剪时间 cutTime 需减去重合或包含的时间段时间
                cut.forEach(item => {
                    if (item.bt >= text.bt && item.et <= text.et) {
                        cutTime -= (item.et - item.bt);
                    } else if (item.bt <= text.bt && item.et >= text.et) {
                        cutTime -= (text.et - text.bt);
                    }
                });
                cut.push({bt:text.bt, et:text.et});
            }
        })
        textPoint.forEach((item, index) => {
            if (sentences === '') {
                bt = item.bt;
            }
            words.push({
                bt: utils.msToSrt(item.bt),
                text: item.text,
                et: utils.msToSrt(item.et)
            })
            if (reg.test(item.text) || index === textPoint.length - 1) {
                sentences = sentences + item.text;
                //去掉结尾符号
                sentences = sentences.trim();
                if (reg.test(sentences.substring(sentences.length - 1, sentences.length))) {
                    sentences = sentences.substr(0, sentences.length - 1);
                }
                if (sentences !== '') {
                    let obj = {
                        bt:utils.msToSrt(bt),
                        et:utils.msToSrt(item.et),
                        seq:seq,
                        words:words,
                        s:sentences,
                    }
                    seq = seq + 1;
                    results.push(obj);
                }
                words = [];
                bt = '';
                sentences = '';
            } else {
                sentences = sentences + item.text;
            }
        })
    })
    let saveJson = {
        results:results,
        cut:cut,
        version:version,
        recordId:recordId,
    };
    if (text.length == 0 && deleteClip.length !== 0) {
        saveJson.cut = deleteClip;
    }
    return saveJson
}

//保存编辑到服务器
textProcess.uploadUserJsonContent = (editorId, editorType, json, isEditorText) => {
    // isEditorText 是否编辑过userJson
    return new Promise(function (resolve) {
        let functionName;
        if (editorType === constants.EDITOR_TYPE_RECORD_CLIENT) {
            functionName = 'uploadUserJsonContent';
        } else if (editorType === constants.EDITOR_TYPE_RECORD_WEB || editorType === constants.EDITOR_TYPE_SCREEN_CLIENT) {
            functionName = 'uploadWebRecordUserJsonContent';
        } else if (editorType === constants.EDITOR_TYPE_DUMP_VIDEO) {
            functionName = 'uploadDumpVideoUserJsonContent';
        } else {
            functionName = 'uploadOrderUserJsonContent';
        }
        let saveJson = JSON.parse(JSON.stringify(json))
        //删除无用对象
        for (let i = 0; i < saveJson.length; i++) {
            saveJson[i].content = '';
            saveJson[i].contentPureText = '';
            if (saveJson[i].pureText) {
                delete saveJson[i].pureText
            }
        }
        vuex.commit('setEditorSaveState', 'saving');
        request[functionName](editorId, saveJson, isEditorText).then(() => {
            resolve();
            vuex.commit('setUserJsonLastSaveTime', new Date().getTime());
            vuex.commit('setEditorSaveState', 'finish');
        }).catch(e => {
            resolve(e);
            vuex.commit('setEditorSaveState', 'finish');
        })
    })
};
//添加标记 迁移方法
textProcess.markWordCombinationParagraphs = (text, isDeletePointClipParagraph) => {
    // 标记单词组合段落
    let index = 0;
    // 可能有误,方法变更了 原本為 addParagraphEndPoint
    text = textProcess.addParagraphEndPoint(text, isDeletePointClipParagraph)
    for (let number = 0; number < text.length; number++) {
        let item = text[number];
        text[number].content = '';
        if (item.text.length === 0) {
            text.splice(number, 1);
            number = number - 1;
        } else {
            let point = ''
            item.text.forEach((itemText, num) => {
                text[number].text[num].index = index;
                if (itemText.type === constants.SENTENCE_POINT || itemText.type === constants.SENTENCE_LINE_FEED) {
                    let text = itemText.text;
                    if (itemText.type === constants.SENTENCE_POINT) {
                        text = '•';
                    }
                    point = point + text;
                    if (num === item.text.length - 1) {
                        point = '<span class="hide-point">' + point + '</span>'
                        text[number].content = text[number].content + point
                        point = '';
                    }
                } else {
                    let addText = itemText.text;
                    if (point !== '') {
                        point = '<span class="hide-point">' + point + '</span>'
                        if (itemText.type === constants.SENTENCE_DELETE) {
                            addText = '<span class="hide-delete">' + addText + '</span>'
                        }
                        text[number].content = text[number].content + point + addText
                        point = '';
                    } else {
                        if (itemText.type === constants.SENTENCE_DELETE) {
                            addText = '<span class="hide-delete">' + addText + '</span>'
                        }
                        text[number].content = text[number].content + addText
                    }
                }
                index = index + 1;
            })
        }
    }
    return text
}
//获取删除片段 迁移方法
textProcess.getDeletedClip = (text) => {
    let deleteClip = [];
    for (let i = 0; i < text.length; i++) {
        let paragraph = text[i].text;
        for (let j = 0; j < paragraph.length; j++) {
            if (paragraph[j].type === constants.SENTENCE_DELETE) {
                deleteClip.push({
                    bt:paragraph[j].bt,
                    et:paragraph[j].et,
                })
            }
        }
    }
    return deleteClip
}
//创建删除节点 迁移方法
textProcess.createDeletePoint = (bt, et) => {
    return {
        id:uuid(),
        bt:bt,
        et:et,
        text:'[...]',
        deleteContent:[],
        type:constants.SENTENCE_DELETE,
    }
}

//获取存储样式
textProcess.getStyleJson = (captionJsonRemotePath) => {
    return new Promise((resolve, reject) => {
        request.getFile(captionJsonRemotePath).then(data => {
            resolve(data);
        }).catch(e => {
            console.log(e)
            reject([]);
        })
    })
}
//处理字幕，自动换行
textProcess.computeLines = function (text, fontSize, fontFamily, width){
    fontSize = Math.round(fontSize);
    let textWordArray = [];
    let c = document.getElementById('myCanvas');
    let ctx = c.getContext("2d");
    let font = fontSize + 'px ' + fontFamily;
    ctx.font = font;
    if (text.indexOf("\n") > -1) {
        text = text.replace(/(\r\n)|(\n)/g,'<br>');
        return text;
    } else {
        // 如果一行可以放下，不需要处理
        if (ctx.measureText(text).width <= width) {
            return text;
        } else {
            let allWord = '';
            let mLineTexts = [];
            textWordArray = text.split(" ");
            let sb = '';
            for (let i = 0; i < textWordArray.length; i++) {
                let word = textWordArray[i];
                word = word + ' ';
                if (ctx.measureText(word).width > width) {
                    for (let j = 0; j < word.length; j++) {
                        sb = sb +  word.substring(j, j + 1);
                        if (ctx.measureText(sb).width + ((sb.length - 1)) > width) {
                            mLineTexts.push(sb.substring(0, sb.length - 1));
                            sb = sb.substring(sb.length - 1, sb.length);
                        }
                    }
                } else {
                    sb = sb + word;
                    if (ctx.measureText(sb).width > width) {
                        mLineTexts.push(sb.substring(0, sb.length - word.length));
                        sb = sb.substring(sb.length - word.length, sb.length);
                    }
                }
                sb = sb + ' ';
            }
            if (sb.length > 0) {
                mLineTexts.push(sb.substring(0, sb.length - 1));
            }
            for (let i = 0; i < mLineTexts.length; i++) {
                allWord = allWord + '<br>' + mLineTexts[i];
            }
            if (allWord.substring(0, 4) === '<br>') {
                allWord  =  allWord.substring(4, allWord.length);
            }
            return allWord;
        }
    }
}
export default textProcess;
