const { v4: uuid } = require('uuid');
let element = {};

// 元素类型
element.ELEMENT_TYPE_SCREEN = 1; // 屏幕
element.ELEMENT_TYPE_CAMERA = 2; // 摄像头
element.ELEMENT_TYPE_CAPTION = 3; // 字幕

element.createElement = (x, y, w, h, type, option) => {
    let videoElement = {
        id:uuid(), //唯一id
        position:{x, y}, //视频在画布上的位置 百分比
        size:{w, h}, //视频在画布上的大小 百分比
        type, //元素类型 录制视频，摄像头
        option //需要保存的其他属性（裁剪信息、video dom、摄像头模式、宽高信息等）信息
    }
    return videoElement;
}

export default element;
