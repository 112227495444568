import chars from '../utils/trad';
function charConvert(ch) {
    let CJK_UNIFIED_IDEOGRAPHS_START = 19968;
    let CJK_UNIFIED_IDEOGRAPHS_END = 40869;
    let chCode = ch.charCodeAt(0);
    if (chCode >= CJK_UNIFIED_IDEOGRAPHS_START && chCode <= CJK_UNIFIED_IDEOGRAPHS_END) {
        return chars[chCode - CJK_UNIFIED_IDEOGRAPHS_START];
    } else {
        return ch;
    }
}
export default function (srcStr) {
    if (srcStr !== '') {
        let targetStringBuilder = '';
        for (let i = 0; i < srcStr.length; i++) {
            targetStringBuilder = targetStringBuilder + (charConvert(srcStr[i]));
        }
        return targetStringBuilder
    } else {
        return ''
    }
}
