<template>
    <div id="app" ref="vueDom">
        <!--画布计算字宽度-->
        <canvas id="myCanvas" width="10" height="10" class="canvas">
        </canvas>
        <router-view></router-view>
<!--        <vizard-wx-warning v-if="displayWxWarning"></vizard-wx-warning>-->
    </div>
</template>

<script>
import send from './utils/sendStatistics';
// import VizardWxWarning from "./components/vizardWxWarning";
import utils from "./utils/utils";
import constants from "./utils/constants";
// const BrowserLogger = require('alife-logger');
export default {
    name: 'App',
    data(){
        return {
            displayWxWarning:false,
            deferredPrompt:{},
        }
    },
    // components: {VizardWxWarning},
    destroyed() {
        window.removeEventListener('beforeinstallprompt', this.beforeinstallprompt, true)
        window.removeEventListener('appinstalled', this.appinstalled, true)
    },
    methods:{
        beforeinstallprompt(e){
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.$store.commit('setDeferredPrompt', e)
            if (localStorage.getItem(constants.WEB_PWA_TIPS_INFO)) {
                let obj = JSON.parse(localStorage.getItem(constants.WEB_PWA_TIPS_INFO));
                if (obj.num < 3 && (new Date().getTime() - obj.closeTime) >= 24 * 60 * 60 * 1000) {
                    this.$store.commit('setPwaInstallState', true)
                    send.collect(send.showPWAguidePopup)
                }
            } else {
                this.$store.commit('setPwaInstallState', true)
                send.collect(send.showPWAguidePopup)
            }
            send.collect(send.beforeinstallprompt)
            send.profileSet({
                is_installPWA:'未安装'
            })
            // Update UI notify the user they can install the PWA
            // Optionally, send analytics event that PWA install promo was shown.
            console.log(`'beforeinstallprompt' event was fired.`);
        },
        appinstalled(){
            // Hide the app-provided install promotion
            this.$store.commit('setDeferredPrompt', null)
            this.$store.commit('setPwaInstallState', false)
            send.collect(send.appInstalled)
            send.profileSet({
                is_installPWA:'安装'
            })
            // Clear the deferredPrompt so it can be garbage collected
            // Optionally, send analytics event to indicate successful install
            console.log('PWA was installed');
        },
    },
    mounted() {
        // 百度
        // let s = document.getElementsByTagName("script")[0];
        // let hm = document.createElement("script");
        // let src = "https://hm.baidu.com/hm.js?" + process.env.VUE_APP_BAIDU_SRC;
        // hm.src = src;
        // hm.id = 'baidu_script';
        // hm.crossOrigin = 'true';
        // if (document.getElementById('baidu_script') == null) {
        //     s.parentNode.insertBefore(hm, s);
        // }
        //ali前端监控
        // https://www.npmjs.com/package/alife-logger
        // let aliScript = document.createElement("script");
        // aliScript.src = "https://retcode.alicdn.com/retcode/bl.js";
        // aliScript.crossOrigin = 'true';
        // s.parentNode.insertBefore(aliScript, s);
        // window.__bl = {};
        // window.__bl.config={
        //     pid:"ahx1xdd0xa@10a6a04c1c9711d",
        //     appType:"web",
        //     imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
        //     sendResource:true,
        //     enableLinkTrace:true,
        //     behavior:true,
        //     enableSPA:true,
        //     useFmp:true,
        //     enableConsole:true,
        //     environment:process.env.VUE_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev'
        // };
        this.$store.commit('setDisplayMenuType', utils.getTypeDisplayModel());
        //初始化统计
        send.init();
        //监听pwa是否可以安装
        window.addEventListener('beforeinstallprompt', this.beforeinstallprompt, true);
        window.addEventListener('appinstalled', this.appinstalled, true);
        if (utils.isPC()) {
            //微信提示
            if (utils.isWx()) {
                this.displayWxWarning = true;
            }
        }
    }
}
</script>

<style>
    html{
        height: 100%;
        width: 100%;
    }
    body{
        height: 100%;
        width: 100%;
        margin: 0px;
        font-weight: 400;
        font-family: PingFangSC, "Microsoft YaHei", 微软雅黑, sans-serif !important;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
    *{
        font-family: PingFangSC, "Microsoft YaHei", 微软雅黑, sans-serif !important;
    }
    #app{
        height: 100%;
        width: 100%;
        color: #373739;
    }
    .canvas{
        z-index: -1;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .share-popup-close{
        width: 12px;
        height: 40px;
        cursor: pointer;
        background: url("images/icon_close_share_popup.png") no-repeat center;
        display: inline-block;
        vertical-align: top;
        background-size: contain;
    }
    :focus-visible{
        outline: none;
    }
    :focus {
        outline-color: -webkit-focus-ring-color;
        outline-style: none;
        outline-width: 0px;
    }
    a:focus,input:focus,p:focus,div:focus{
        -webkit-tap-highlight-color:rgba(0,0,0,0);
    }
</style>
