import Vue from "vue"
import Vuex from "vuex"
import style from "../utils/style";
import element from "../utils/videoElement";
import textProcess from "../utils/textProcess";
import constants from "../utils/constants";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        listMinHeight:0,
        isResumeLoading:false,
        isRecording:false,
        isRecordDetailShown:false,
        loadingText:"",
        isPromptPopupShown:false,
        isSupportRecord:true,
        isClipMode:false,
        isUpdatePrePlayTime:true,
        videoIsPlay:false,
        userInfo:{},
        isShareWithMeDetails:false,
        isWhitelistUser:false,
        menu:undefined, // 1-全部视频；2-我的导出(已停用)；3-上传视频(已废弃)；4-我的分享（已废弃）；5-分享给我；6-视频库；7-首页；
        isLowIndex:false,
        isPermissionShown:false,
        isRecordAudio:true,
        isUserAsrCountTooltips:false,
        isAsrCourseShown:false,
        userTotalAsrCount:0,
        userLeftAsrCount:0,
        asrWarningShown:false,
        openAsrCourseType:'detail',
        isReadyDisplayClipCourse:false,
        isExportFinish:false,
        isCloseDisplayCourse:false,
        editorType:0,
        finalVideoId:'',
        editorId:0,
        isRecordShiftMovePosition:false,
        action:[],
        editorLoadingShown:false,
        editorLoadingText:"",

        isVideoEditor:false,
        isMouseDown:false,
        isReadyDownloadShown:false,
        isDownLoadingShown:false,
        downloadVideoUrl:'',
        isEditor:false,
        isMultipleModel:false,
        isCheckboxSelectAll:false,
        multipleCheckParagraph:[],
        editorLang:1,
        isFocus:false,
        limit:30,//撤销限制
        asrState:0,
        shareCode:"",
        isAdmin:false,
        deferredPrompt:{},
        displayPwaInstall:false,
        isRecordTutorialShown:false,
        cacheRecordingFile:"",
        ffmpegCreateState:false,
        displayMenuType:1,

        videoLibraryList:[],
        isLibDetailsShown: false,
        libraryId: undefined,
        isShowLibCreateBtn: false,
        isLibDetailNull: true,
        isAfterSearch: false,
    //    弹窗信息
        isDeleteWindowDisplay:false,
        deleteWindowOption:{
            title:"",
            text:"",
            callback:"",
            cancelCallBack:"",
        },
        isRenameWindowDisplay:false,
        renameWindowOption:{
            text:"",
            callback:""
        },
        isRecordOrUpload:false,
        recordPermissionObj:{ //权限弹窗传递的参数
            buttonText: '我知道了',
            width:'480',
            height:'550',
            isSpecial: true,
            imgName:'pic_record_tutorial.svg',
            title:'Vizard 需要获得使用您的麦克风的权限 ',
            otherText:'了解更多',
            macSubtitle:'对于Mac用户',
            text:'您可能需要检查系统偏好设置，允许浏览器访问您的麦克风和屏幕录制权限。',
        },

        recordMediaStream:'',//录制媒体流
        editorModel:'doc',
        personAccountDisplay: false,//用户个人中心
        changePhotoWindowDisplay: false,//修改头像
        asrOriginalJson:{}, //识别的原始结果
        asrUserJson:[], //用户显示和操作的数据结构
        isEditorUserJson:false,//userJson是否被编辑过
        currentTime:0,
        sentenceArray:[], //句子
        notes:[],//注释
        notesMaskDisplay:false,//注释弹窗出现时空白处禁止点击
        notesMaskType:undefined, // 1-文本区域注释，2-注释列表
        currentNotesIndex:undefined,//当前注释Index
        jsonVersion:0,//
        deleteClip:[], //删除的时间段
        clipVideoDuration:0, //删除的总时长
        isEditorVideoPlaying:false,//编辑器播放状态
        editorPlayingSentence:'',//编辑器播放中的句子
        editorTrimOperatorShow:false,//是否显示trim模式操作栏
        editorSentence:[],//编辑器句子,
        userJsonLastSaveTime:0,//userJson最后保存时间
        editorSaveState:'finish', //保存状态
        sharePageModel:'video',
        isPositionNotes:true, //是否需要重新定位注释
        cameraStream:'', //摄像头流
        isCollapse:false, //侧边栏默认展开
        isAllowSetPwd:false, //默认不设置密码保护
        isShowMobileCodePage:false,
        shareWindowRenameSuccessCallback:'',
        isEditorHeaderShareDisplay:false, //当文档注释指引开启，编辑器分享弹窗默认关闭
        fatherVideoLibId:undefined,
        sonVideoLibId:undefined,
        libraryMenuArray:[],
        shareFatherId:undefined,
        isOwner:false, //用于标识是用户本人打开了自己分享的视频库
        //视频 元素通过element.createElement()创建
        editorVideos:[],
        // 编辑器录制屏幕样式
        editorRecordScreenStyle:JSON.parse(JSON.stringify(style.videoStyle)),
        // 编辑器录制摄像头样式
        editorRecordCameraStyle:JSON.parse(JSON.stringify(style.videoStyle)),
        //字幕样式
        editorCaptionStyle:JSON.parse(JSON.stringify(style.captionStyle)),
        //视频背景样式
        editorVideoBackground:JSON.parse(JSON.stringify(style.videoBackground)),
        // 编辑器是否显示视频元素的操作器
        hexPredefineColors:[],//16进制颜色
        rgbaPredefineColors:[],//带透明度的颜色
        //编辑器字幕样式和信息
        editorCaptionInfo: {
            isLoading: false,
        },
        //视频形状 默认不带圆角的矩形
        editorVideoRadiusType:constants.VIDEO_CUT_SHAPE_RECTANGLE,
        cameraShape:constants.CAMERA_SHAPE_CIRCLE,
        isColorTooltipShow:true,
        fullScreenDpr:1,
        isDisplayOperator:false,
        hasSendLoginRequest:false,
        userData:null,
        isShowExportPopUp:false, //是否显示导出搬家引导弹窗（057）
        isHasNewExport: false,//导出红点(057)
        isShowShareGuide: false,//分享引导弹窗（057）
        isVideoCliping: false, // 是否视频裁剪中（通过音频裁剪）
        guideShareBtnPosition: 0, // 功能引导所需的分享按钮位置
        isQuickShare:false,//是否是item底部的快捷分享连链接
        isCloudSpaceProUser: false, // 是否是云空间权益用户
        videoTotalVolume: 0, // 用户视频体积总量
        cloudSpaceTotalVolume:0, // 用户云空间总量
        userCloudSpaceList: [], // 用户云空间包列表
        isMenuCloudSpaceLoading: true, // 是否工作台菜单栏里的云空间区 loading
        expiredVideoNum: 0, //已过期视频数目
        expiredVideoDisplay: "",//视频过期提示标识
        upgradeToShareCallback: {}, //工作台中免费用户点击分享提示升级的回调
    },
    mutations:{
        //设置视频过期提示标识
        setExpiredVideoDisplay(state, value) {
            state.expiredVideoDisplay = value;
        },
        //修改已过期视频数目
        setExpiredVideoNum(state, value) {
            state.expiredVideoNum = value;
        },
        //修改字幕样式单个属性
        updateEditorCaptionStyleByKey(state, param){
            state.editorCaptionStyle[param.key] = param.value;
        },
        //初始化字幕
        setEditorCaptionStyle(state, value){
            state.editorCaptionStyle = value;
        },
        //修改背景
        updateEditorVideoBackgroundByKey(state, param){
            state.editorVideoBackground[param.key] = param.value;
        },
        //初始化背景
        setEditorVideoBackground(state, value){
            state.editorVideoBackground = value;
        },
        // 更新视频元素
        updateEditorVideoElementByKey(state, param){
            state.editorVideos[param.index][param.key] = param.value;
        },
        // 初始化视频元素
        setEditorVideos(state, value){
            state.editorVideos = value;
        },
        // 更新编辑器的频样式
        updateEditorVideoStyleByKey(state, param){
            if (param.type === element.ELEMENT_TYPE_SCREEN) {
                state.editorRecordScreenStyle[param.key] = param.value;
            } else if (param.type === element.ELEMENT_TYPE_CAMERA) {
                state.editorRecordCameraStyle[param.key] = param.value;
            }
        },
        // 初始化编辑器视频样式
        setEditorVideoStyle(state, param){
            if (param.type === element.ELEMENT_TYPE_SCREEN) {
                state.editorRecordScreenStyle = param.value;
            } else if (param.type === element.ELEMENT_TYPE_CAMERA) {
                state.editorRecordCameraStyle = param.value;
            }
        },
        // 设置「是否显示编辑器视频操作器」的值
        setIsDisplayOperator(state, value){
            state.isDisplayOperator = value;
        },
        setRecordMediaStream(state, value){
            if (state.recordMediaStream) {
                state.recordMediaStream.getTracks().forEach(track => {
                    track.stop();
                })
            }
            state.recordMediaStream = value;
        },
        clearRecordMediaStream(state) {
            if (state.recordMediaStream) {
                state.recordMediaStream.getTracks().forEach(track => {
                    track.stop();
                })
            }
            state.recordMediaStream = '';
        },
        setCameraMediaStream(state, value){
            if (state.cameraStream) {
                state.cameraStream.getTracks().forEach(track => {
                    track.stop();
                })
            }
            state.cameraStream = value;
        },
        clearCameraMediaStream(state) {
            if (state.cameraStream) {
                state.cameraStream.getTracks().forEach(track => {
                    track.stop();
                })
            }
            state.cameraStream = '';
        },
        setIsPositionNotes(state, value){
            state.isPositionNotes = value
        },
        setEditorSaveState(state, value){
            state.editorSaveState = value
        },
        setUserJsonLastSaveTime(state, value){
            state.userJsonLastSaveTime = value
        },
        setJsonVersion(state, value){
            state.jsonVersion = value
        },
        setIsEditorUserJson(state, value){
            state.isEditorUserJson = value
        },
        setNotesMaskType(state, value){
            state.notesMaskType = value
        },
        setCurrentNotesIndex(state, value){
            state.currentNotesIndex = value
        },
        setNotesMaskDisplay(state, value){
            state.notesMaskDisplay = value
        },
        setNotes(state, value){
            state.notes = value
        },
        setSentenceArray(state, value){
            state.sentenceArray = value
        },
        setCurrentTime(state, value){
            state.currentTime = value
        },
        setAsrUserJson(state, value) {
            //增加段尾占位节点
            let userJson = textProcess.addParagraphEndPoint(value);
            //组合句子
            state.sentenceArray = textProcess.getSentence(userJson);

            state.asrUserJson = userJson
        },
        updateAsrUserJson(state, value){
            let userJson = state.asrUserJson;
            let paragraphBt = userJson[value.paragraphIndex].text[0].bt;
            let paragraphEt = userJson[value.paragraphIndex].text[userJson[value.paragraphIndex].text.length - 1].et;
            userJson[value.paragraphIndex].text = [];
            if (value.words.length === 0) {
                userJson[value.paragraphIndex].isEditorNull = true;
                //增加占位点
                if ((paragraphEt - paragraphBt) < constants.INTERVALS_TIME) {
                    userJson[value.paragraphIndex].text.push({
                        bt:paragraphBt,
                        et:paragraphEt,
                        text:"",
                        type:constants.SENTENCE_POINT,
                    })
                } else {
                    let bt = paragraphBt;
                    let num = Math.ceil((paragraphEt - paragraphBt) / constants.INTERVALS_TIME);
                    for (let i = 0; i < num; i++) {
                        let pointObj = {
                            bt:bt,
                            et:(bt + constants.INTERVALS_TIME) < paragraphEt ? (bt + constants.INTERVALS_TIME) : paragraphEt,
                            text:"",
                            type:constants.SENTENCE_POINT,
                        }
                        userJson[value.paragraphIndex].text.push(pointObj)
                        bt = pointObj.et;
                    }
                }
            } else {
                let words = value.words;
                let time = paragraphBt;
                for (let i = 0; i < words.length; i++) {
                    words[i].type = constants.SENTENCE_TEXT;
                    //添加占位圆点
                    let intervals = words[i].bt - time;
                    if (intervals >= constants.WORD_INTERVALS_TIME || (i === 0 &&  words[i].bt !== time)) {
                        let num = Math.ceil(intervals / constants.INTERVALS_TIME);
                        let preEndTime = time;
                        for (let k = 0; k < num; k++) {
                            let pointObj = {};
                            if (k === num - 1) {
                                pointObj = {
                                    bt:preEndTime,
                                    et:words[i].bt,
                                    text:"",
                                    type:constants.SENTENCE_POINT,
                                }
                            } else {
                                pointObj = {
                                    bt:preEndTime,
                                    et:preEndTime + constants.INTERVALS_TIME,
                                    text:"",
                                    type:constants.SENTENCE_POINT,
                                }
                                preEndTime = preEndTime + constants.INTERVALS_TIME;
                            }
                            userJson[value.paragraphIndex].text.push(pointObj);
                        }
                    }
                    time = words[i].et;
                    userJson[value.paragraphIndex].text.push(words[i]);
                }
                let lastIndex =  userJson[value.paragraphIndex].text.length - 1
                let endWord = userJson[value.paragraphIndex].text[lastIndex];
                if (endWord.et < paragraphEt) {
                    let num = Math.ceil((paragraphEt - endWord.et)  / constants.INTERVALS_TIME);
                    let preEndTime = endWord.et;
                    for (let k = 0; k < num; k++) {
                        let pointObj = {};
                        if (k === num - 1) {
                            pointObj = {
                                bt:preEndTime,
                                et:paragraphEt,
                                text:"",
                                type:constants.SENTENCE_POINT,
                            }
                        } else {
                            pointObj = {
                                bt:preEndTime,
                                et:preEndTime + constants.INTERVALS_TIME,
                                text:"",
                                type:constants.SENTENCE_POINT,
                            }
                            preEndTime = preEndTime + constants.INTERVALS_TIME;
                        }
                        userJson[value.paragraphIndex].text.push(pointObj);
                    }
                }
            }
            let newUserJson = textProcess.addParagraphEndPoint(userJson, false);
            //组合句子
            state.sentenceArray = textProcess.getSentence(newUserJson);
            state.asrUserJson = newUserJson
        },
        clearAsrUserJson(state){
            state.asrUserJson = []
        },
        setAsrOriginalJson(state, value) {
            state.asrOriginalJson = value
        },
        setEditorModel(state, value) {
            state.editorModel = value
        },
        setDeleteWindowDisplay(state, value) {
            state.isDeleteWindowDisplay = value
        },
        setDeleteWindowOption(state, value) {
            state.deleteWindowOption = value
        },
        setRenameWindowDisplay(state, value) {
            state.isRenameWindowDisplay = value
        },
        setRenameWindowOption(state, value) {
            state.renameWindowOption = value
        },


        setListMinHeight(state, value) {
            state.listMinHeight = value
        },
        setIsAfterSearch(state, value) {
            state.isAfterSearch = value
        },
        setIsLibDetailNull(state, value) {
            state.isLibDetailNull = value
        },
        setIsShowLibCreateBtn(state, value) {
            state.isShowLibCreateBtn = value
        },
        setLibraryId(state, value) {
            state.libraryId = value
        },
        setLibDetailsShown(state, value) {
            state.isLibDetailsShown = value
        },
        setDisplayMenuType(state, value) {
            state.displayMenuType = value
        },
        setFfmpegCreateState(state, value){
            state.ffmpegCreateState = value
        },
        setCacheRecordingFile(state, value){
            state.cacheRecordingFile = value
        },
        setRecordLoadingState(state, value){
            state.isResumeLoading = value
        },
        setRecordTutorialState(state, value){
            state.isRecordTutorialShown = value
        },
        setPwaInstallState(state, value){
            state.displayPwaInstall = value
        },
        setDeferredPrompt(state, value){
            state.deferredPrompt = value
        },
        setAdmin(state, value){
            state.isAdmin = value
        },
        setShareCode(state, value){
            state.shareCode = value
        },
        setAsrState(state, value){
            state.asrState = value
        },
        setVideoEditorState(state, value){
            state.isVideoEditor = value
        },
        setEditorLoadingShown(state, value){
            state.editorLoadingShown = value
        },
        setEditorLoadingText(state, value){
            state.editorLoadingText = value
        },
        clearAction(state){
            state.action = [];
        },
        saveAction(state, newAction){
            state.action.unshift(newAction);
            if (state.action.length > state.limit) {
                state.action.splice(state.action.length - 1, 1);
            }
        },
        deleteAction(state){
            if (state.action.length !== 0) {
                state.action.splice(0, 1);
            }
        },
        setRecordMovePositionState(state, value){
            state.isRecordShiftMovePosition = value
        },
        setEditorId(state, value){
            state.editorId = value
        },
        setFinalVideoId(state, value) {
            state.finalVideoId = value;
        },
        setEditorType(state, value){
            state.editorType = value
        },
        setExportState(state, value){
            state.isExportFinish = value
        },
        setCloseDisplayCourseState(state, value){
            state.isCloseDisplayCourse = value
        },
        setReadyDisplayClipCourseState(state, value){
            state.isReadyDisplayClipCourse = value
        },
        setOpenAsrCourseType(state, value){
            state.openAsrCourseType = value
        },
        setAsrWarningShown(state, value){
            state.asrWarningShown = value
        },
        setUserTotalAsrCount(state, value){
            state.userTotalAsrCount = value
        },
        setUserLeftAsrCount(state, value){
            state.userLeftAsrCount = value
        },
        setAsrCourseState(state, value){
            state.isAsrCourseShown = value
        },
        setUserCountTooltipsState(state, value){
            state.isUserAsrCountTooltips = value
        },
        setRecordAudioState(state, value){
            state.isRecordAudio = value
        },
        setPermission(state, value){
            state.isPermissionShown = value
        },
        setLowIndex(state, value){
            state.isLowIndex = value
        },
        setMenuTab(state, value){
            state.menu = value
        },
        setUserInfo(state, value){
            state.userInfo = value;
        },
        setIsWhitelistUser(state, value){
            state.isWhitelistUser = value;
        },
        setVideoPlayState(state, value){
            state.videoIsPlay = value
        },
        setShareWithMeDetailsState(state, value){
            state.isShareWithMeDetails = value
        },
        setUpdatePrePlayTime(state, value){
            state.isUpdatePrePlayTime = value
        },
        setSupportRecordState(state, value){
            state.isSupportRecord = value
        },
        setRecordingState(state, value){
            state.isRecording = value
        },
        setRecordDetailShown(state, value){
            state.isRecordDetailShown = value
        },
        setPromptPopupState(state, value){
            state.isPromptPopupShown = value
        },
        setLoadingText(state, value){
            state.loadingText = value;
        },
        setClipModeState(state, value){
            state.isClipMode = value;
        },


        setReadyDownloadState(state, value){
            state.isReadyDownloadShown = value
        },
        setDownloadingState(state, value){
            state.isDownLoadingShown = value
        },
        setDownloadVideoUrl(state, value){
            state.downloadVideoUrl = value
        },
        setFocusState(state, value){
            state.isFocus = value
        },
        setEditorLang(state, value){
            state.editorLang = value
        },
        setCheckboxSelectAll(state, value){
            state.isCheckboxSelectAll = value
        },
        pushMultipleCheckParagraph(state, value){
            state.multipleCheckParagraph.push(value)
        },
        setMultipleCheckParagraph(state, value){
            state.multipleCheckParagraph = value
        },
        setMultipleModel(state, value){
            state.isMultipleModel = value
        },
        setEditorState(state, value){
            state.isEditor = value
        },
        changeMouseState(state, value){
            state.isMouseDown = value;
        },
        setVideoLibraryList(state, value){
            state.videoLibraryList = value;
        },
        updateVideoLibraryList(state, value){
            Vue.set(state.videoLibraryList[value.index], 'childLib', value.item)
        },
        setRecordorUpload(state, value){
            state.isRecordOrUpload = value;
        },
        setRecordPermissionObj(state, value){
            state.recordPermissionObj = value;
        },
        setPersonAccountDisplay(state, value) {
            state.personAccountDisplay = value
        },
        setChangePhotoWindowDisplay(state, value) {
            state.changePhotoWindowDisplay = value
        },
        setDeleteClip(state, deleteClip){
            //删除片段合并
            for (let i = 0; i < deleteClip.length; i++) {
                if (i !== deleteClip.length - 1) {
                    if (deleteClip[i].et === deleteClip[i + 1].bt) {
                        deleteClip[i].et = deleteClip[i + 1].et
                        deleteClip.splice(i + 1, 1);
                        i--
                    }
                }
            }
            state.deleteClip = deleteClip;
        },
        setEditorPlayingSentence(state, value){
            state.editorPlayingSentence = value
        },
        setEditorVideoPlayState(state, value){
            state.isEditorVideoPlaying = value
        },

        setEditorTrimOperatorShow(state, value){
            state.editorTrimOperatorShow = value;
        },
        setEditorSentence(state, value){
            state.editorSentence = value;
        },
        setClipVideoDuration(state, value){
            state.clipVideoDuration = value;
        },
        setSharePageModel(state, value) {
            state.sharePageModel = value
        },
        setCameraStream(state, value) {
            state.cameraStream = value;
        },
        setMenuCollapse(state, value) {
            state.isCollapse = value
        },
        setShareWindowRenameSuccessCallback(state, value) {
            state.shareWindowRenameSuccessCallback = value
        },
        setShowMobileCodePage(state, value) {
            state.isShowMobileCodePage = value;
        },
        setEditorHeaderShareDisplay(state, value) {
            state.isEditorHeaderShareDisplay = value
        },
        setFatherVideoLibId(state, value) {
            state.fatherVideoLibId = value;
        },
        setSonVideoLibId(state, value) {
            state.sonVideoLibId = value;
        },
        setLibraryMenuArray(state, value) {
            state.libraryMenuArray = value;
        },
        setShareFatherId(state, value) {
            state.shareFatherId = value;
        },
        setIsOwner(state, value) {
            state.isOwner = value;
        },
        setHexPredefineColors(state, value){
            state.hexPredefineColors = value
        },
        setRgbaPredefineColors(state, value){
            state.rgbaPredefineColors = value
        },
        updateEditorCaptionInfoByKey(state, param){
            state.editorCaptionInfo[param.key] = param.value
        },
        setEditorCaptionInfo(state, value){
            state.editorCaptionInfo = value
        },
        setColorTooltipShow(state, value){
            state.isColorTooltipShow = value;
        },
        setFullScreenDpr(state, value){
            state.fullScreenDpr = value
        },
        setEditorVideoRadiusType(state, value) {
            state.editorVideoRadiusType = value
        },
        setCameraShape(state, value) {
            state.cameraShape = value
        },
        setHasSendLoginRequest(state, value) {
            state.hasSendLoginRequest = value;
        },
        setUserData(state, value) {
            state.userData = value;
        },
        showExportPopUp(state, value) {
            state.isShowExportPopUp = value;
        },
        setHasNewExport(state, value) {
            state.isHasNewExport = value;
        },
        setShowShareGuide(state, value) {
            state.isShowShareGuide = value;
        },
        setVideoClipStatus(state, value) {
            state.isVideoCliping = value;
        },
        setGuideShareBtnPosition(state, value) {
            state.guideShareBtnPosition = value;
        },
        setIsQuickShare(state, value) {
            state.isQuickShare = value
        },
        setIsCloudSpaceProUser(state, value) {
            state.isCloudSpaceProUser = value;
        },
        setVideoTotalVolume(state, value) {
            state.videoTotalVolume = value;
        },
        setCloudSpaceTotalVolume(state, value) {
            state.cloudSpaceTotalVolume = value;
        },
        setUserCloudSpaceList(state, value) {
            state.userCloudSpaceList = value;
        },
        setIsMenuCloudSpaceLoading(state, value) {
            state.isMenuCloudSpaceLoading = value;
        },
        setUpgradeToShareCallback(state, value) {
            state.upgradeToShareCallback = value;
        }
    }
})
