import constants from "../utils/constants";

let style = {};

// 视频样式
style.videoStyle = {
    // 裁剪相关的位置大小参数
    sx:0,
    sy:0,
    sw:1,
    sh:1,
    // 实际显示的位置大小参数
    x:0,
    y:0,
    w:1,
    h:1
}

// 字幕默认样式
style.captionStyle = {
    x:0,
    y:0.83,
    w:1,
    fontSize: 16, // 字号
    fontFamily: "FandolHei", // 字体
    fontFamilyId: "1000", // 字体 id（值参考 font.js）
    textAlign: "center", // 对齐方式 left、center、right
    fontWeight: "", // 加粗
    fontStyle: "", // 斜体
    textDecoration: "", // 下划线
    color: "#FFFFFF", // 字体颜色
    textShadow: "", // 字体描边
    backgroundColor: "rgba(0, 0, 0, 0.8)", // 背景色
    rows:1,
}

//背景图片fontColor
style.videoBackground = {
    backgroundType: constants.EXPORT_BACKGROUND_TYPE_NOT,
    backgroundColor: '#000000',
    backgroundImage:{
        url:'',
        // id:'',
        // size:'',
    }
}

export default style;
