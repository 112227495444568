let send = {};
import utils from "./utils";
send.homepage_zhihu = 'zhihu';
send.homepage_bilibili = 'bilibili';
send.homepage_startRecording = 'homepage_startRecording';
send.homepage_palyVideo = 'homepage_palyVideo';
send.homepage_newRegisterUser = 'homepage_newRegisterUser';
send.homepage_clickLogin = 'homepage_clickLogin';
send.homepage_showLogin = 'homepage_showLogin';
send.homepage_confirmLogin = 'homepage_confirmLogin';
send.homepage_clickRegister = 'homepage_clickRegister';
send.homepage_showRegister = 'homepage_showRegister';
send.homepage_confirmRegister = 'homepage_confirmRegister';
send.homepage_contactUs = 'contactUs';
send.homepage_FAQ = 'FAQ';
send.homepage_feedback = 'feedback';
send.workbench_myRecording = 'workbench_myRecording';
send.workbench_videoTime = 'workbench_videoTime';
send.workbench_startRecording = 'workbench_startRecording';
send.workbench_clickShare = 'workbench_clickShare';
send.workbench_stopRecording = 'workbench_stopRecording';
send.workbench_unsupportRecording = 'workbench_unsupportRecording';
send.workbench_downloadChrome = 'workbench_downloadChrome';
send.workbench_downloadClient = 'workbench_downloadClient';
send.workbench_showDLPage = 'workbench_showDLPage';
send.workbench_confirmDownload = 'workbench_confirmDownload';
send.workbench_openVideo = 'workbench_openVideo';
send.workbench_renameVideo = 'workbench_renameVideo';
send.workbench_deleteVideo = 'workbench_deleteVideo';
send.workbench_myExport = 'workbench_myExport';
send.workbench_downloadVideo = 'workbench_downloadVideo';
send.workbench_search = 'workbench_search';
send.detailsPage_back = 'detailsPage_back';
send.detailsPage_showDetailsPage = 'detailsPage_showDetailsPage';
send.detailsPage_playVideo = 'detailsPage_playVideo';
send.detailsPage_pauseVideo = 'detailsPage_pauseVideo';
send.detailsPage_editName = 'detailsPage_editName';
send.detailsPage_slideHandle = 'detailsPage_slideHandle';
send.detailsPage_cancelClip = 'detailsPage_cancelClip';
send.detailsPage_saveClip = 'detailsPage_saveClip';
send.detailsPage_downloadVideo = 'detailsPage_downloadVideo';
send.detailsPage_shareVideo = 'detailsPage_shareVideo';
send.detailsPage_copyLink = 'detailsPage_copyLink';
send.detailsPage_showSharePage = 'detailsPage_showSharePage';
send.workbench_addFavorites = 'workbench_addFavorites';
send.workbench_closeFavorites = 'workbench_closeFavorites';
send.homepage_downloadClient = 'homepage_downloadClient';

send.detailsPage_showReasonsForDownload = 'detailsPage_showReasonsForDownload';
send.detailsPage_confirmDownload = 'detailsPage_confirmDownload';
send.detailsPage_cancelDownload = 'detailsPage_cancelDownload';


send.homepage_WeChat = 'homepage_WeChat';
send.homepage_bindingTel = 'homepage_bindingTel';
send.homepage_confirmBinding = 'homepage_confirmBinding';



send.workbench_view = 'workbench_view';
// send.workbench_showSharePage = 'workbench_owSharePage';
send.detailsPage_showDetailsPage = 'detailsPage_showDetailsPage';
send.detailsPage_onlineClip = 'detailsPage_onlineClip';
send.detailsPage_downloadText = 'detailsPage_downloadText';
send.detailsPage_shareVideo = 'detailsPage_shareVideo';
send.detailsPage_linkShare = 'detailsPage_linkShare';
send.detailsPage_WeChatShare = 'detailsPage_WeChatShare';
send.detailsPage_copyAppletCode = 'detailsPage_copyAppletCode';



send.workbench_myShare = 'workbench_myShare';
send.workbench_shareWithMe = 'workbench_shareWithMe';
send.editPage_showSharePage = 'editPage_showSharePage';

send.homepage_personCenter = 'homepage_personCenter';
send.homepage_bindWeChat = 'homepage_bindWeChat';
send.homepage_bindphone = 'homepage_bindphone';
send.homepage_bindEmail = 'homepage_bindEmail';
send.homepage_editPassword = 'homepage_editPassword';
send.homepage_photo = 'homepage_photo';
send.homepage_username = 'homepage_username';

send.detailsPage_AutoPauseVideo = 'detailsPage_AutoPauseVideo';
send.detailsPage_dragProgressBar = 'detailsPage_dragProgressBar';


send.homepage_verificationTab = 'homepage_verificationTab';
send.homepage_accountTab = 'homepage_accountTab';
send.homepage_phoneTab = 'homepage_phoneTab';
send.homepage_emailTab = 'homepage_emailTab';


send.workbench_openMicPermission = 'workbench_openMicPermission';
send.workbench_NoPermission = 'workbench_NoPermission';
send.workbench_PermissionGuidance = 'workbench_PermissionGuidance';
send.workbench_closePermissionGuidance = 'workbench_closePermissionGuidance';
send.workbench_copyChromeLink = 'workbench_copyChromeLink';
send.workbench_ViewTutorial = 'workbench_ViewTutorial';
send.workbench_recordingFailed = 'workbench_recordingFailed';
send.detailsPage_retryDownload = 'detailsPage_retryDownload';


send.myShare_shareVideo = 'myShare_shareVideo';
send.myShare_linkShare = 'myShare_linkShare';
send.myShare_copyLink = 'myShare_copyLink';
send.myShare_WeChatShare = 'myShare_WeChatShare';
send.myShare_copyAppletCode = 'myShare_copyAppletCode';
send.workbench_cancelSharing = 'workbench_cancelSharing';
send.workbench_videoTranscoding = 'workbench_videoTranscoding';


send.detailsPage_Rename = 'detailsPage_Rename';
send.detailsPage_clipVideo = 'detailsPage_clipVideo';
send.detailsPage_text_clipping = 'detailsPage_text_clipping';
send.Play_tutorial_video1 = 'Play_tutorial_video1';
send.detailsPage_Transcribe_text1 = 'detailsPage_Transcribe_text1';
send.detailsPage_Cancel_transcription1 = 'detailsPage_Cancel_transcription1';
send.Video_to_text_popup = 'Video_to_text_popup';
send.detailsPage_Transcribe_text3 = 'detailsPage_Transcribe_text3';
send.detailsPage_Cancel_transcription = 'detailsPage_Cancel_transcription';
send.detailsPage_TranscriptionsExhausted = 'detailsPage_TranscriptionsExhausted';
send.tutorial = 'tutorial';
send.editPage_showEditPage = 'editPage_showEditPage';
send.Question_Mark_Text_Clip_Tutorial = 'Question_Mark_Text_Clip_Tutorial';
send.Transcribe_operation_tutorial_popup1 = 'Transcribe_operation_tutorial_popup1';
send.Transcribe_operation_tutorial_popup2 = 'Transcribe_operation_tutorial_popup2';
send.Play_tutorial_video2 = 'Play_tutorial_video2';
send.detailsPage_Transcribe_text2 = 'detailsPage_Transcribe_text2';

send.first_web_recording_time = 'first_web_recording_time';
send.first_recording_time = 'first_recording_time';
send.web_recording_number = 'web_recording_number';
send.client_recording_number = 'client_recording_number';



send.workbench_Recording = 'workbench_Recording';
send.editPage_playVideo = 'editPage_playVideo';
send.editPage_pauseVideo = 'editPage_pauseVideo';
send.editPage_dragProgressBar = 'editPage_dragProgressBar';
send.editPage_AutoPauseVideo = 'editPage_AutoPauseVideo';
send.editPage_logo = 'editPage_logo';
send.editPage_back = 'editPage_back';
send.editPage_tutorial = 'editPage_tutorial';
send.show_video_clip_tutorial_popup = 'show_video_clip_tutorial_popup';
send.play_video_clip_tutorial = 'play_video_clip_tutorial';
send.editPage_Rename = 'editPage_Rename';
send.editPage_simpleCut = 'editPage_simpleCut';
send.editPage_clipHandle = 'editPage_clipHandle';
send.editPage_cancelClip = 'editPage_cancelClip';
send.editPage_saveClip = 'editPage_saveClip';
send.start_transcribing = 'start_transcribing';
send.transcription_complete = 'transcription_complete';
send.show_Transcribe_Times_popup = 'show_Transcribe_Times_popup';
send.transcribe_text = 'transcribe_text';
send.cancel_Transcribe = 'cancel_Transcribe';
send.show_Transcribe_Exhausted_popup = 'show_Transcribe_Exhausted_popup';
send.editPage_exportVideo = 'editPage_exportVideo';
send.editPage_retryDownload = 'editPage_retryDownload';
send.editPage_shareVideo = 'editPage_shareVideo';
send.editPage_linkShare = 'editPage_linkShare';
send.editPage_copyLink = 'editPage_copyLink';
send.editPage_WeChatShare = 'editPage_WeChatShare';
send.editPage_copyAppletCode = 'editPage_copyAppletCode';
send.editPage_subtitles_on = 'editPage_subtitles_on';
send.editPage_subtitles_off = 'editPage_subtitles_off';
send.editPage_videoEdit = 'editPage_videoEdit';
send.editPage_search_editMode = 'editPage_search_editMode';
send.editPage_revoke_editMode = 'editPage_revoke_editMode';
send.editPage_textRevoke = 'editPage_textRevoke';
send.editPage_clickEdit_normalMode = 'editPage_clickEdit_normalMode';
send.editPage_clickEdit_editMode = 'editPage_clickEdit_editMode';
send.editPage_clickDelete = 'editPage_clickDelete';
send.editPage_finishEdit = 'editPage_finishEdit';
send.editPage_finishVideoEdit = 'editPage_finishVideoEdit';
send.editPage_Transcribe = 'editPage_Transcribe';


send.beforeinstallprompt = 'beforeinstallprompt';
send.addVizardToDesktop = 'addVizardToDesktop';
send.callToInstallPWAPopup = 'callToInstallPWAPopup';
send.appInstalled = 'appInstalled';
send.closeInstallPWAPopup = 'closeInstallPWAPopup';


send.showRecordingTutorialPopup = 'showRecordingTutorialPopup';
send.closeRecordingTutorialPopup = 'closeRecordingTutorialPopup';
send.workbench_pauseRecording = 'workbench_pauseRecording';
send.workbench_resumeRecording = 'workbench_resumeRecording';
send.workbench_pauseDuration = 'workbench_pauseDuration';
send.editPage_click_closeDownloadWindow = 'editPage_click_closeDownloadWindow';
send.editPage_click_beginDownloadButton = 'editPage_click_beginDownloadButton';
send.editPage_downloadDuration = 'editPage_downloadDuration';
send.workbench_synchronousDataDuration = 'workbench_synchronousDataDuration';

send.emptyResult_ViewTutorial = 'emptyResult_ViewTutorial';
send.transcriptionResult_empty = 'transcriptionResult_empty';
send.transcriptionResult_notEmpty = 'transcriptionResult_notEmpty';
send.workbench_dataBlockSize = 'workbench_dataBlockSize';


send.workbench_showProgressBar = 'workbench_showProgressBar';
send.workbench_showNetworkException = 'workbench_showNetworkException';
send.workbench_resyncData = 'workbench_resyncData';
send.workbench_AutoGenerateRecordings = 'workbench_AutoGenerateRecordings';

send.editPage_web_downloadDuration = 'editPage_web_downloadDuration';

send.loginRegister_openWeChatPage = 'loginRegister_openWeChatPage';
send.loginRegister_jumpOffWeChatPage = 'loginRegister_jumpOffWeChatPage';
send.sharePopup_clickBindWeChat = 'sharePopup_clickBindWeChat';
send.sharePopup_openWeChatPage = 'sharePopup_openWeChatPage';
send.jumpOffWeChatPage = 'jumpOffWeChatPage';

send.editPage_be_downloadDuration = 'editPage_be_downloadDuration';

send.workbench_mySpace = 'workbench_mySpace';
send.myShare_copyLink = 'myShare_copyLink';
send.myShare_copyAppletCode = 'myShare_copyAppletCode';
send.clickSaveVideoToMySpace = 'clickSaveVideoToMySpace';
send.showSaveSuccessfullyPopup = 'showSaveSuccessfullyPopup';
send.clickToViewVideo = 'clickToViewVideo';
send.closeSaveSuccessfullyPopup = 'closeSaveSuccessfullyPopup';
send.editPage_automatic_Transcribe = 'editPage_automatic_Transcribe';
send.detailsPage_copyLink = 'detailsPage_copyLink';
send.detailsPage_copyAppletCode = 'detailsPage_copyAppletCode';
send.editPage_copyLink = 'editPage_copyLink';
send.editPage_copyAppletCode = 'editPage_copyAppletCode';
send.editPage_openSave = 'editPage_openSave';
send.editPage_closeSave = 'editPage_closeSave';
send.editPage_videoTranscoding = 'editPage_videoTranscoding';



send.workbench_shareVideo = 'workbench_shareVideo';
send.workbench_linkShare = 'workbench_linkShare';
send.workbench_copyLink = 'workbench_copyLink';
send.workbench_WeChatShare = 'workbench_WeChatShare';
send.workbench_copyAppletCode = 'workbench_copyAppletCode';
send.editPage_FullScreen = 'editPage_FullScreen';
send.editPage_exitFullScreen = 'editPage_exitFullScreen';



send.videoSource_notDetected = 'videoSource_notDetected';
send.audioSource_notDetected = 'audioSource_notDetected';
send.turnOff_microphone = 'turnOff_microphone';
send.cancel_recording = 'cancel_recording';
send.otherExceptionsCannotRecord = 'otherExceptionsCannotRecord';

send.clickappletPreviewBtn = 'clickappletPreviewBtn';
send.appletPreviewShowAppletCode = 'appletPreview_showAppletCode';
send.appletPreviewClickBindWeChat = 'appletPreview_clickBindWeChat';
send.appletPreviewOpenWeChatPage = 'appletPreview_openWeChatPage'


send.workbench_gridmode = 'workbench_gridmode';
send.workbench_listmode = 'workbench_listmode';
send.workbench_microphone_on = 'workbench_microphone_on';
send.workbench_microphone_off = 'workbench_microphone_off';
send.editPage_deleteVideo = 'editPage_deleteVideo';


send.closePWAguidePopup = 'closePWAguidePopup';
send.showPWAguidePopup = 'showPWAguidePopup';


send.homepage_downloadWindowsVersion = 'homepage_downloadWindowsVersion';
send.homepage_downloadMacVersion = 'homepage_downloadMacVersion';
send.iknow_unsupportRecording = 'iknow_unsupportRecording';

//视频库相关
send.addToPersonalLibrary_myVideoTab = 'addToPersonalLibrary_myVideoTab';
send.createLibrary_AddToPersonalLibrary = 'createLibrary_AddToPersonalLibrary';
send.checkLibrary_AddToPersonalLibrary = 'checkLibrary_AddToPersonalLibrary';
send.confirm_AddToPersonalLibrary = 'confirm_AddToPersonalLibrary';
send.cancel_AddToPersonalLibrary = 'cancel_AddToPersonalLibrary';
send.confirm_CreatePersonalLibrary = 'confirm_CreatePersonalLibrary';
send.cancel_CreatePersonalLibrary = 'cancel_CreatePersonalLibrary';
send.personalLibrary_workbench = 'personalLibrary_workbench';
send.closePersonalLibrary_workbench = 'closePersonalLibrary_workbench';
send.open_PersonalLibrary = 'open_PersonalLibrary';
send.rename_PersonalLibrary = 'rename_PersonalLibrary';
send.delete_PersonalLibrary = 'delete_PersonalLibrary';

//新录制相关pingback
send.workbench_clickRecordVideo = 'workbench_clickRecordVideo';
send.workbench_showRecordPage = 'workbench_showRecordPage';
send.workbench_clickChooseScreenMain = 'workbench_clickChooseScreenMain';
send.workbench_clickChooseScreenBtn = 'workbench_clickChooseScreenBtn';
send.workbench_chooseScreenFinish = 'workbench_chooseScreenFinish';
send.workbench_clickCancelShareScreen = 'workbench_clickCancelShareScreen';
send.workbench_showSysMicNoShareToast = 'workbench_showSysMicNoShareToast';
send.workbench_clickHowtoShareSysMic = 'workbench_clickHowtoShareSysMic';
send.workbench_switchMicInput = 'workbench_switchMicInput';
send.workbench_clickMicBtn = 'workbench_clickMicBtn';
send.workbench_clickMicBtnNoPermission = 'workbench_clickMicBtnNoPermission';
send.workbench_skipCountDown = 'workbench_skipCountDown';
send.workbench_retakeRecording = 'workbench_retakeRecording';
send.workbench_clickDeleteRecordingBtn = 'workbench_clickDeleteRecordingBtn';
send.workbench_confirmDeleteRecordingBtn = 'workbench_confirmDeleteRecordingBtn';
send.workbench_clickFinishRecord = 'workbench_clickFinishRecord'

send.homePage_finishLoading = 'homePage_finishLoading';
send.homePage_clickUseNowFirstBtn = 'homePage_clickUseNowFirstBtn';
send.homePage_clickVizardComment = 'homePage_clickVizardComment';
send.homePage_clickNavigationProduct = 'homePage_clickNavigationProduct';
send.homePage_clickNavigationScene = 'homePage_clickNavigationScene';
send.homePage_clickNavigationResource = 'homePage_clickNavigationResource';
send.homePage_learnMoreRecording = 'homePage_learnMoreRecording';
send.homePage_learnMoreTranscription = 'homePage_learnMoreTranscription';
send.homePage_learnMoreTextEditVideo = 'homePage_learnMoreTextEditVideo';
send.homePage_learnMoreShare = 'homePage_learnMoreShare';
send.homePage_learnMoreNotes = 'homePage_learnMoreNotes';
send.homePage_learnMoreEnterprise = 'homePage_learnMoreEnterprise';
send.homePage_learnMoreTeaching = 'homePage_learnMoreTeaching';
send.homePage_learnMoreStudent = 'homePage_learnMoreStudent';
send.homePage_showRecordingIntroPage = 'homePage_showRecordingIntroPage';
send.homePage_clickUseRecordingIntroPage = 'homePage_clickUseRecordingIntroPage';
send.homePage_showTransciptionIntroPage = 'homePage_showTransciptionIntroPage';
send.homePage_clickUseTransciptionIntroPage = 'homePage_clickUseTransciptionIntroPage';
send.homePage_showTextEditVideoIntroPage = 'homePage_showTextEditVideoIntroPage';
send.homePage_clickUseTextEditVideoIntroPage = 'homePage_clickUseTextEditVideoIntroPage';
send.homePage_showShareIntroPage = 'homePage_showShareIntroPage';
send.homePage_clickUseShareIntroPage = 'homePage_clickUseShareIntroPage';
send.homePage_showPersonalLibraryIntroPage = 'homePage_showPersonalLibraryIntroPage';
send.homePage_clickUsePersonalLibraryIntroPage = 'homePage_clickUsePersonalLibraryIntroPage';
send.homePage_showEnterpriseScenePage = 'homePage_showEnterpriseScenePage';
send.homePage_clickUseEnterpriseScenePage = 'homePage_clickUseEnterpriseScenePage';
send.homePage_showTeachingScenePage = 'homePage_showTeachingScenePage';
send.homePage_clickUseTeachingScenePage = 'homePage_clickUseTeachingScenePage';
send.homePage_showStudentScenePage = 'homePage_showStudentScenePage';
send.homePage_clickUseStudentScenePage = 'homePage_clickUseStudentScenePage';



send.editor_clickVideoMode = 'editor_clickVideoMode';
send.editor_clickDocMode = 'editor_clickDocMode';
send.editor_clickLinkIcon = 'editor_clickLinkIcon';
send.editor_clickTranscribe = 'editor_clickTranscribe';
send.editor_clickContinueRolling = 'editor_clickContinueRolling';
send.editor_clickText = 'editor_clickText';
send.editor_clickNote = 'editor_clickNote';
send.editor_clickHideTextNote = 'editor_clickHideTextNote';

send.editor_hoverUnderlinedText = 'editor_hoverUnderlinedText';
send.editor_clickUnderlinedText = 'editor_clickUnderlinedText';
send.editor_noteClickTranscribe = 'editor_noteClickTranscribe';



send.editor_clickShowNote = 'editor_clickShowNote';
send.editor_adjustWidth = 'editor_adjustWidth';
send.editor_clickAddNote = 'editor_clickAddNote';
send.editor_clickProfilePhoto = 'editor_clickProfilePhoto';
send.editor_clickConfirmAdd = 'editor_clickConfirmAdd';
send.editor_clickCancelAdd = 'editor_clickCancelAdd';
send.editor_selectNote = 'editor_selectNote';
send.editor_clickEditNote = 'editor_clickEditNote';
send.editor_clickConfirmEdit = 'editor_clickConfirmEdit';
send.editor_clickCancelEdit = 'editor_clickCancelEdit';
send.editor_clickDeleteNote = 'editor_clickDeleteNote';
send.editor_clickConfirmDelete = 'editor_clickConfirmDelete';
send.editor_addNoteFailed = 'editor_addNoteFailed';
send.editor_clickTrimNoText = 'editor_clickTrimNoText';
send.trim_clickSave = 'trim_clickSave';
send.trim_soundtrack_handle = 'trim_soundtrack_handle';

//工作台个人中心相关
send.workbench_clickProfilePhoto = 'workbench_clickProfilePhoto';
send.workbench_clickUsername = 'workbench_clickUsername';
send.workbench_clickChangePwd = 'workbench_clickChangePwd';
send.workbench_clickLinkPhone = 'workbench_clickLinkPhone';
send.workbench_clickLinkEmail = 'workbench_clickLinkEmail';
send.workbench_clickLinkWechat = 'workbench_clickLinkWechat';
send.workbench_confirmLinkPhone = 'workbench_confirmLinkPhone';
send.workbench_confirmLinkEmail = 'workbench_confirmLinkEmail';

send.editor_clickMore = 'editor_clickMore';
send.workbench_showSharePage = 'workbench_showSharePage';
send.editor_transcribeLanguageShow = 'editor_transcribeLanguageShow';
send.editor_selectTranscribeLanguage = 'editor_selectTranscribeLanguage';

//v0.5.1 导出相关
send.export_popupClickView = 'export_popupClickView';
send.export_popupClickGotIt = 'export_popupClickGotIt';
send.export_clickRefresh = 'export_clickRefresh';
send.export_clickDownload = 'export_clickDownload';
send.export_clickDelete = 'export_clickDelete';
send.export_clickRename = 'export_clickRename';

//v0.5.1 视频剪辑相关
send.trim_clickSearch = 'trim_clickSearch';
send.trim_deleteText = 'trim_deleteText';
send.trim_recoverText = 'trim_recoverText';
send.editor_clickTrimHasText = 'editor_clickTrimHasText';

//v0.5.1 上传相关
send.upload_clickReupload = 'upload_clickReupload';
send.alert_upload_stayHere = 'alert_upload_stayHere';
send.upload_finish = 'upload_finish';
send.upload_start = 'upload_start';
send.upload_browse = 'upload_browse';
send.upload_dragDrop = 'upload_dragDrop';

send.workbench_showGuideStepOne = 'workbench_showGuideStepOne';
send.workbench_clickGuideStepOneKnow = 'workbench_clickGuideStepOneKnow';
send.workbench_closeGuideStepOne = 'workbench_closeGuideStepOne';
send.workbench_showGuideStepTwo = 'workbench_showGuideStepTwo';
send.workbench_clickGuideStepTwoNext = 'workbench_clickGuideStepTwoNext';
send.workbench_closeGuideStepTwoClose = 'workbench_closeGuideStepTwoClose';
send.workbench_showGuideStepThree = 'workbench_showGuideStepThree';
send.workbench_clickGuideStepThreeKnow = 'workbench_clickGuideStepThreeKnow';
send.workbench_closeGuideStepThreeClose = 'workbench_closeGuideStepThreeClose';
send.workbench_clickCreateVideo = 'workbench_clickCreateVideo';
send.workbench_homeClickRecord = 'workbench_homeClickRecord';
send.workbench_homeClickUpload = 'workbench_homeClickUpload';
send.workbench_homeClickTutorial = 'workbench_homeClickTutorial';
send.workbench_showTutorial = 'workbench_showTutorial';
send.workbench_closeTutorial = 'workbench_closeTutorial';
send.workbench_myVideoViewAll = 'workbench_myVideoViewAll';
send.workbench_myExportViewAll = 'workbench_myExportViewAll';
send.workbench_clickHome = 'workbench_clickHome';
send.workbench_clickMyVideo = 'workbench_clickMyVideo';
send.workbench_clickMyExport = 'workbench_clickMyExport';
send.workbench_clickHelpCenter = 'workbench_clickHelpCenter';
send.workbench_clickFAQ = 'workbench_clickFAQ';
send.workbench_clickFeedback = 'workbench_clickFeedback';
send.workbench_feedbackSubmit = 'workbench_feedbackSubmit';
// send.workbench_clickContactUs = 'workbench_clickContactUs';
send.workbench_clickProfile = 'workbench_clickProfile';
send.workbench_clickAccountSetting = 'workbench_clickAccountSetting';
send.workbench_clickSignOut = 'workbench_clickSignOut';

send.editor_clickViewAudience = 'editor_clickViewAudience';
send.trim_soundtrack_split = 'trim_soundtrack_split';
send.trim_soundtrack_select = 'trim_soundtrack_select';
send.trim_soundtrack_delete = 'trim_soundtrack_delete';
send.trim_soundtrack_timeline = 'trim_soundtrack_timeline';
send.trim_soundtrack_min = 'trim_soundtrack_min';
send.trim_soundtrack_max = 'trim_soundtrack_max';
send.trim_soundtrack_zoomIn = 'trim_soundtrack_zoomIn';
send.trim_soundtrack_zoomOut = 'trim_soundtrack_zoomOut';
send.trim_soundtrack_dragZoom = 'trim_soundtrack_dragZoom';

send.sharePage_save = 'sharePage_save';
send.sharePage_linkShare = 'sharePage_linkShare';
send.sharePage_copyLink = 'sharePage_copyLink';
send.sharePage_WeChatShare = 'sharePage_WeChatShare';
send.sharePage_copyAppletCode = 'sharePage_copyAppletCode';
send.sharePage_clickLinkIcon = 'sharePage_clickLinkIcon';
send.sharePage_clickVideoMode = 'sharePage_clickVideoMode';
send.sharePage_clickDocMode = 'sharePage_clickDocMode';
send.sharePage_clickText = 'sharePage_clickText';
send.sharePage_clickNote = 'sharePage_clickNote';
send.sharePage_clickHideTextNote = 'sharePage_clickHideTextNote';
send.sharePage_clickShowText = 'sharePage_clickShowText';
send.sharePage_ClickShowNote = 'sharePage_ClickShowNote';
send.sharePage_clickHideNote = 'sharePage_clickHideNote';
send.sharePage_clickShowNote = 'sharePage_clickShowNote';

send.h5_clickLogin = 'h5_clickLogin';
send.h5_clickWechatLogin = 'h5_clickWechatLogin';
send.h5_clickForgotPwd = 'h5_clickForgotPwd';
send.h5_clickPlay = 'h5_clickPlay';
send.h5_clickPause = 'h5_clickPause';
send.h5_clickCCOn = 'h5_clickCCOn';
send.h5_clickCCOff = 'h5_clickCCOff';
send.h5_clickPlaybackSpeed = 'h5_clickPlaybackSpeed';
send.h5_clickVolume = 'h5_clickVolum';
send.h5_clickNoteBtn = 'h5_clickNoteBtn';
send.h5_clickCloseNote = 'h5_clickCloseNote';

send.h5_clickShare = 'h5_clickShare';
send.h5_showSavePopUp = 'h5_showSavePopUp';

send.h5_clickSave = 'h5_clickSave';
send.h5_showSavePopUp = 'h5_showSavePopUp';
send.h5_clickSaveCopyLink = 'h5_clickSaveCopyLink';
send.h5_closeSavePopUp = 'h5_closeSavePopUp';
send.h5_clickLocateCurrentPlayTime = 'h5_clickLocateCurrentPlayTime';
send.h5_openPage = 'h5_openPage';

send.workbench_recordingNotSupport = 'workbench_recordingNotSupport';
send.workbench_recordingNotSupportClickDownload = 'workbench_recordingNotSupportClickDownload';
send.workbench_recordingNotSupportClickCancel = 'workbench_recordingNotSupportClickCancel';


send.editor_clickShowText = 'editor_clickShowText';
send.editor_hoverProfilePhoto = 'editor_hoverProfilePhoto';
send.editor_clickHideNote = 'editor_clickHideNote';
send.editor_clickHideText = 'editor_clickHideText';
send.sharePage_clickHideText = 'sharePage_clickHideText';
send.editor_doubleClickToEditText = 'editor_doubleClickToEditText';
send.editor_showGuideStepOne = 'editor_showGuideStepOne';
send.editor_clickGuideStepOneNext = 'editor_clickGuideStepOneNext';
send.editor_closeGuideStepOne = 'editor_closeGuideStepOne';
send.editor_showGuideStepTwo = 'editor_showGuideStepTwo';
send.editor_clickGuideStepTwoNext = 'editor_clickGuideStepTwoNext';
send.editor_closeGuideStepTwo = 'editor_closeGuideStepTwo';
send.editor_showGuideStepThree = 'editor_showGuideStepThree';
send.editor_clickGuideStepThreeKnow = 'editor_clickGuideStepThreeKnow';
send.editor_closeGuideStepThree = 'editor_closeGuideStepThree';
send.homePage_showNoteIntroPage = 'homePage_showNoteIntroPage';
send.homePage_clickUseNoteIntroPage = 'homePage_clickUseNoteIntroPage';
send.editor_copyTranscript = 'editor_copyTranscript';

send.Workbench_opensidebar = 'Workbench_opensidebar';
send.Workbench_closesidebar = 'Workbench_closesidebar';
send.workbench_showRecordingGuideStepOne = 'workbench_showRecordingGuideStepOne';
send.workbench_closeRecordingGuideStepOne = 'workbench_closeRecordingGuideStepOne';
send.workbench_showRecordingGuideStepTwo = 'workbench_showRecordingGuideStepTwo';
send.workbench_closeRecordingGuideStepTwoClose = 'workbench_closeRecordingGuideStepTwoClose';
send.workbench_clickRecordingGuideStepTwoKnow = 'workbench_clickRecordingGuideStepTwoKnow';
send.workbench_clickRecordingGuideStepTwoNoMoreReminder = 'workbench_clickRecordingGuideStepTwoNoMoreReminder';
send.workbench_clickcamera = 'workbench_clickcamera';
send.workbench_CameraSquareType = 'workbench_CameraSquareType';
send.workbench_CameraRectangleType = 'workbench_CameraRectangleType';
send.workbench_CameraRoundType = 'workbench_CameraRoundType';
send.workbench_CameraClickResizingPen = 'workbench_CameraClickResizingPen';
send.workbench_CameraDrag = 'workbench_CameraDrag';
send.workbench_CameraResizing = 'workbench_CameraResizing';
send.workbench_CameraResizingSave = 'workbench_CameraResizingSave';
send.workbench_CameraResizingCancel = 'workbench_CameraResizingCancel';
send.workbench_CameraCloseSetting = 'workbench_CameraCloseSetting';
send.workbench_CameraHitsMaxSizing = 'workbench_CameraHitsMaxSizing';
send.workbench_CameraHitsMinSizing = 'workbench_CameraHitsMinSizing';
send.workbench_CameraHitsBoundary = 'workbench_CameraHitsBoundary';
send.workbench_SitePermissionDeny = 'workbench_SitePermissionDeny';
send.workbench_showNoSitePermissionToast = 'workbench_showNoSitePermissionToast';
send.workbench_BrowserPermissionClose = 'workbench_BrowserPermissionClose';
send.workbench_BrowserPermissionDisplay = 'workbench_BrowserPermissionDisplay';

send.workbench_searchinTranscription = 'workbench_searchinTranscription';
send.sharepage_EnterPasscode = 'sharepage_EnterPasscode';
send.workbench_ClickShareVideoIcon = 'workbench_ClickShareVideoIcon';
send.editor_ClickQuickShare = 'editor_ClickQuickShare';
send.workbench_copySharingLinkIcon = 'workbench_copySharingLinkIcon';
send.workbench_copySharingLink = 'workbench_copySharingLink';
send.workbench_ClickPasscodeProtection = 'workbench_ClickPasscodeProtection';
send.workbenche_SavePasscode = 'workbenche_SavePasscode';
send.workbench_ClickViewTranscriptionSetting = 'workbench_ClickViewTranscriptionSetting';
send.workbench_WeChatQRCodeShare = 'workbench_WeChatQRCodeShare';
send.workbench_FeishuCodeShare = 'workbench_FeishuCodeShare';
send.workbench_DingDingCodeShare = 'workbench_DingDingCodeShare';
send.workbench_QRCodeShare = 'workbench_QRCodeShare';
send.workbench_copyWechatQRCode = 'workbench_copyWechatQRCode';
send.workbench_SharetabVideoRenamed = 'workbench_SharetabVideoRenamed';
send.workbench_SwitchViewerSaveSetting = 'workbench_SwitchViewerSaveSetting';

send.workbench_cameraonly = 'workbench_cameraonly';
send.workbench_screenonly = 'workbench_screenonly';
send.workbench_screenandcamera = 'workbench_screenandcamera';
send.workbench_startcamera = 'workbench_startcamera';
send.workbench_switchCameraInput = 'workbench_switchCameraInput';
send.workbench_CameraAutoResizing = 'workbench_CameraAutoResizing';
send.workbench_CameraAutoRepositioning = 'workbench_CameraAutoRepositioning';
send.workbench_showNoCameraSourceToast = 'workbench_showNoCameraSourceToast';
send.CameraSource_notDetected = 'CameraSource_notDetected';

// 0.55
send.workbench_shareFolder = 'workbench_shareFolder';
send.workbench_copyFolderLink = 'workbench_copyFolderLink';


send.workbench_clickTemplate = 'workbench_clickTemplate';
send.workbench_ApplyNoTemplate = 'workbench_ApplyNoTemplate';
send.workbench_SharedScreenResizing = 'workbench_SharedScreenResizing';
send.workbench_ScreenDrag = 'workbench_ScreenDrag';

send.workbench_selectAll = 'workbench_selectAll';
send.workbench_removevideos = 'workbench_removevideos';
send.addToPersonalLibrary_fromPersonalLibrary = 'addToPersonalLibrary_fromPersonalLibrary';
send.workbench_deletevideos = 'workbench_deletevideos';

//0.5.6
send.editor_changeFont = 'editor_changeFont';
send.editor_chooseFontSize = 'editor_chooseFontSize';
send.editor_changeFontSize = 'editor_changeFontSize';
send.editor_changeSubtitleAligment = 'editor_changeSubtitleAligment';
send.editor_changeSubtitleStyle = 'editor_changeSubtitleStyle';
send.editor_changeSubtitleColor = 'editor_changeSubtitleColor';
send.sharepage_save_clickView = 'sharepage_save_clickView';
send.sharepage_save_clickGotIt = 'sharepage_save_clickGotIt';
send.workbench_save_clickRefresh = 'workbench_save_clickRefresh';
send.editor_closeTranscribeGuide = 'editor_closeTranscribeGuide';
send.editor_closeEditGuide = 'editor_closeEditGuide';
send.editor_clickEditGuideTry = 'editor_clickEditGuideTry';
send.workbench_clickShareLink = 'workbench_clickShareLink';

// 视频编辑器页面 0.56
send.editor_videoSelectObject = 'editor_videoSelectObject';
send.editor_resizeCaption = 'editor_resizeCaption';
send.editor_dragVideo = 'editor_dragVideo';
send.editor_cutScreenRecording = 'editor_cutScreenRecording';
send.editor_resizeVideoObject = 'editor_resizeVideoObject';
send.editor_chooseTrim = 'editor_chooseTrim';
send.editor_chooseFontStyle = 'editor_chooseFontStyle';
send.editor_chooseBgStyle = 'editor_chooseBgStyle';
send.editor_CameraShapeType = 'editor_CameraShapeType';
send.editor_uploadBgImage = 'editor_uploadBgImage';
send.editor_confirmUploadBgImage = 'editor_confirmUploadBgImage';
send.editor_deletebgimage = 'editor_deletebgimage';
send.editor_usebgImage = 'editor_usebgImage';
send.editor_backgroundColor = 'editor_backgroundColor';

//0.57
send.export_clickDownload = 'export_clickDownload';
send.editor_shareGuide = 'editor_shareGuide';
send.editor_featureGuide = 'editor_featureGuide';

//0.58
send.workbench_newFeatureSurvey = 'workbench_newFeatureSurvey';

//0.60
send.workbench_clickBuySpace = 'workbench_clickBuySpace';
send.workbench_clickIncreaseSpace = 'workbench_clickIncreaseSpace';
send.workbench_showBuySpacePopup = 'workbench_showBuySpacePopup';

send.collect = (teaEventName, teaEventValue) => {
    window.collectEvent(teaEventName, teaEventValue);
    let array = teaEventName.split('_');
    if (array.length > 1) {
        if (teaEventValue) {
            let sendValue = '';
            Object.keys(teaEventValue).forEach((item) => {
                sendValue = sendValue + item + '=' + teaEventValue[item] + ';'
            })
            window._hmt.push(["_trackEvent", array[0], array[1], sendValue]);
        } else {
            window._hmt.push(["_trackEvent", array[0], array[1]]);
        }
    }
}
send.init = function (){
    window.collectEvent('init', {
        app_id: Number(process.env.VUE_APP_VOLC_ID),//app_id
        channel: 'cn', //数据发送地址
        log: false, // 开启调试日志
        enable_ab_test: false, // boolean类型，是否开启A/B实验功能
    });
    window.collectEvent('config', {
        isPwa:utils.isPwa(),
        is_client:false,
        vizard_web_version: process.env.VUE_APP_RELEASE_VERSION,
    });
    window.collectEvent('start');
}

send.config = function (userId) {
    window.collectEvent('config', {
        user_unique_id: userId.toString(),
    });
}

//火山自增属性
send.add = function (value) {
    window.collectEvent('profileIncrement', value);
}
send.append = function (value) {
    window.collectEvent('profileAppend', value);
}
send.profileSet = function (value) {
    window.collectEvent('profileSet', value);
}
export default send
