import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from './store/index';
import VueRouter from 'vue-router'
import Routers from './router'
import vuescroll from 'vuescroll';
import VueClipboard from 'vue-clipboard2';
import Videojs from 'video.js';
import $ from 'jquery';
import './registerServiceWorker';
import './font/iconFont.css';
Vue.prototype.$video = Videojs
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueClipboard);
Vue.config.ignoredElements = ['wx-open-launch-weapp']
window.__bl = {};
window._hmt = window._hmt || [];
window.isRecordAduio = true;
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
let loading;
Vue.prototype.$ = $;
Vue.prototype.startLoading = function (text, background, customClass) {
  let loadingText = text ? text : "加载中"
  let loadingBackground = background ? background : "rgba(255, 255, 255, 1)"
  let loadingCustomClass = customClass ? customClass : ""
  loading = this.$loading({
    lock: true,
    text: loadingText,
    spinner: 'el-icon-loading',
    background: loadingBackground,
    customClass: loadingCustomClass
  });
};
Vue.prototype.endLoading = function () {
  if (loading) {
    loading.close();
  }
}
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: '#c1c1c1',
      showDelay: 500000,
      onlyShowBarOnScroll: false,
    }
  }, // 在这里设置全局默认配置
  name: 'myScroll', // 在这里自定义组件名字，默认是vueScroll
});
// 路由配置
const RouterConfig = {
  mode: 'history',
  routes: Routers
};
const router = new VueRouter(RouterConfig);

const rootVueObj = new Vue({
  router: router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app');
export default rootVueObj;
