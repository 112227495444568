import Vue from 'vue'
import '../element-variables.scss';
import {
    Dialog,
    Button ,
    Message,
    MessageBox,
    Pagination,
    Dropdown,
    Input,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Option,
    OptionGroup,
    ButtonGroup,
    Tooltip,
    Scrollbar,
    Progress,
    Carousel,
    CarouselItem,
    Backtop,
    Rate,
    Icon,
    Loading,
    DatePicker,
    Checkbox,
    TableColumn,
    Table,
    Avatar,
    Image,
    Slider,
    Switch,
    Autocomplete,
    Notification,
    Steps,
    Step,
    Drawer,
    Divider,
    Submenu,
    ColorPicker,
    Popover,
} from 'element-ui'
//全局使用
Vue.use(Steps);
Vue.use(Step);
Vue.use(Autocomplete);
Vue.use(Switch);
Vue.use(Slider);
Vue.use(TableColumn);
Vue.use(Image);
Vue.use(Avatar);
Vue.use(Table);
Vue.use(Checkbox);
Vue.use(Carousel);
Vue.use(DatePicker);
Vue.use(CarouselItem);
Vue.use(Rate);
Vue.use(Icon);
Vue.use(Backtop);
Vue.use(Progress);
Vue.use(Scrollbar);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Tooltip);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Divider);
Vue.use(Submenu);
Vue.use(ColorPicker);
Vue.use(Popover);
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$loading = Loading.service;
