let utils = {};
import Cookies from 'js-cookie';
import Big from 'big.js';
import constants from "./constants";
import Chs2Cht from '../utils/Chs2Cht';
import send from "./sendStatistics";
import ElementUI from 'element-ui';
//防止精度溢出方法
// 加
utils.plus = function(value1, value2) {
    value1 = Number(value1)
    value2 = Number(value2)
    let x = new Big(value1);
    let y = new Big(value2);
    return Number(x.plus(y))
};
// 减
utils.minus = function(value1, value2) {
    value1 = Number(value1)
    value2 = Number(value2)
    let x = new Big(value1);
    let y = new Big(value2);
    return Number(x.minus(y))
};
// 乘
utils.times = function(value1, value2) {
    value1 = Number(value1)
    value2 = Number(value2)
    let x = new Big(value1);
    let y = new Big(value2);
    return Number(x.times(y))
};
// 除
utils.div = function(value1, value2) {
    value1 = Number(value1)
    value2 = Number(value2)
    let x = new Big(value1);
    let y = new Big(value2);
    return Number(x.div(y))
};
//验证邮箱
utils.checkEmail = function(value){
    if (value.length < constants.MAX_LENGTH_OF_NAME) {
        if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)){
            return true
        } else {
            return false
        }
    } else {
        return false
    }
};
utils.getUTCTime = function(){
    return new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds()
    ).getTime() / 1000;
}
//获取分享url
utils.getShareUrl = function (shareCode, type) {
    //type 为true 表示分享的视频库
    let shareType = '';
    if (type) {
        shareType = '&&shareType=2';
    }
    if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
        //线上新网址使用新网址的分享链接
        if (window.location.href.indexOf('vizard.video') !== -1) {
            return process.env.VUE_APP_WEB_URL + 's?code=' + shareCode + shareType;
        } else {
            return process.env.VUE_APP_OLD_WEB_URL + 's?code=' + shareCode + shareType;
        }
    } else {
        return process.env.VUE_APP_WEB_URL + 's?code=' + shareCode + shareType;
    }
}
utils.getSourceToUrl = function () {
    if (Cookies.get(constants.WEB_SOURCE)) {
        let source = Cookies.get(constants.WEB_SOURCE)
        if (Cookies.get(constants.BAIDU_BD_VID)) {
            return '?web_source=' + source + '&web_bd_vid=' + Cookies.get(constants.BAIDU_BD_VID)
        } else if (Cookies.get(constants.VIZARD_360_QHCLICKID)) {
            return '?web_source=' + source + '&web_360_qhclickid=' + Cookies.get(constants.VIZARD_360_QHCLICKID)
        } else {
            return '?web_source=' + source
        }
    } else {
        return ''
    }
}
//是否在pwa下启动
utils.isPwa = function () {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
}
utils.getExploreName = function (){
    var userAgent = navigator.userAgent;
    if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
        return 'Opera';
    }
    else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
        return 'IE';
    }
    else if(userAgent.indexOf("Edge") > -1){
        return 'Edge';
    }
    else if(userAgent.indexOf("Firefox") > -1){
        return 'Firefox';
    }
    else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
        return 'Safari';
    }
    else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
        return 'Chrome';
    }
    else if(!!window.ActiveXObject || "ActiveXObject" in window){
        return 'IE>=11';
    }
    else{
        return 'Unkonwn';
    }
}
//js 复制文字
utils.copyText = (text) => {
    let inputEle = document.createElement("input");
    inputEle.setAttribute("value", text);
    document.body.appendChild(inputEle);
    inputEle.select();
    document.execCommand("copy");
    document.body.removeChild(inputEle);
    ElementUI.Message({
        message: "复制成功",
        type: 'success',
        customClass:'custom-message'
    });
}
//设置配置信息
utils.setConfigInfo = function (config) {
    send.config(config.userId)
}
//换算视频时间
utils.changeVideoTime = (value) => {
    if (value) {
        if(value == "—"){
            return "—"
        } else {
            let h;
            if(value < 3600){
                h = 0
            } else {
                h = Math.floor(value / 3600);
            }
            if(h < 10){
                h = "0" + h;
            }
            let t = Math.floor(value % 3600);
            let m = Math.floor(t/60);
            if(m < 10){
                m = "0" + m;
            }
            let s = value % 60;
            if(s < 10){
                s = "0" + s;
            }
            return h + ":" + m + ":" + s;
        }
    } else {
        return "00:00:00";
    }
}
utils.getHeaderBackgroundColor = (userInfo) => {
    let userId = userInfo.userId;
    if (userInfo.headImageUrl && userInfo.headImageUrl !== '') {
        return 'unset'
    } else {
        let string = userId.toString()
        let lastNumber = Number(string.substring(string.length - 1, string.length));
        return constants.HEADER_BACKGROUND[lastNumber]
    }
}
//横屏
utils.getLandscapeVideoFontSizeByHeight = function (videoWidth) {
    return videoWidth * 17 / 600;
};
//竖屏
utils.getPortraitVideoFontSizeByHeight = function (videoHeight) {
    return videoHeight * 85 / 1920;
};
utils.loadCssCode = function(code){
    let style = document.getElementsByTagName('style')[0];
    style.appendChild(document.createTextNode(code));
};
utils.addFilter = function (srcShadowColor){
    return 'drop-shadow(' + srcShadowColor + ' 1px 1px 0px)';
};
//添加阴影
utils.stroke = function (width, color) {
    let shadow = '';
    for(let i = 0; i <= width; i++) {
        for (let j = 0; j <= width; j++) {
            shadow = shadow + ',' + i + 'px ' + '-' + j + 'px ' + '0 ' + color;
            shadow = shadow + ',' + '-' + i + 'px ' + '-' + j + 'px ' + '0 ' + color;
            shadow = shadow + ',' + i + 'px ' +  j + 'px ' + '0 ' + color;
            shadow = shadow + ',' + '-' + i + 'px ' + j + 'px ' + '0 ' + color;
        }
    }
    shadow = shadow.substr(1);
    return shadow;
};
//是否是pwa
utils.isPwa = function () {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
}

utils.timeAgo = function (dateTimeStamp){   //dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
    var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60;
    var day = hour * 24;
    var week = day * 7;
    var month = day * 30;
    var now = new Date().getTime();   //获取当前时间毫秒
    var diffValue = now - dateTimeStamp;//时间差
    let result = '';
    if(diffValue < 0){
        return;
    }
    var minC = diffValue/minute;  //计算时间差的分，时，天，周，月
    var hourC = diffValue/hour;
    var dayC = diffValue/day;
    var weekC = diffValue/week;
    var monthC = diffValue/month;
    if(monthC >= 1 && monthC < 4){
        result = " " + parseInt(monthC) + "月前"
    }else if(weekC >= 1 && weekC < 4){
        result = " " + parseInt(weekC) + "周前"
    }else if(dayC >= 1 && dayC < 7){
        result = " " + parseInt(dayC) + "天前"
    }else if(hourC >= 1 && hourC < 24){
        result = " " + parseInt(hourC) + "小时前"
    }else if(minC >= 1 && minC < 60){
        result =" " + parseInt(minC) + "分钟前"
    }else if(diffValue >= 0 && diffValue <= minute){
        result = "刚刚"
    }else {
        var datetime = new Date();
        datetime.setTime(dateTimeStamp);
        var Nyear = datetime.getFullYear();
        var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        result = Nyear + "-" + Nmonth + "-" + Ndate
    }
    return result;
}
//录制名称时间
utils.getRecordNameTime = function (timeStamp) {
    if (timeStamp == null) {
        return ""
    } else {
        let date = new Date();
        date.setTime(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        return y.toString() + m.toString() + d.toString() + '_' + h.toString() + '_' +  minute.toString();
    }
};
utils.formatDateTimeSecond = function (timeStamp){
    if (timeStamp) {
        let date = new Date();
        date.setTime(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        let second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d+'-'+h+':'+minute+':'+second;
    } else {
        return "";
    }
};
utils.getChromeVersion = function () {
    let arr = navigator.userAgent.split(' ');
    let chromeVersion = '';
    for(let i = 0;i < arr.length; i++){
        if(/chrome/i.test(arr[i])) {
            chromeVersion = arr[i]
        }
    }
    if(chromeVersion){
        return Number(chromeVersion.split('/')[1].split('.')[0]);
    } else {
        return false;
    }
}
utils.formatDateTimeRegister = function (timeStamp){
    if (timeStamp) {
        let date = new Date();
        date.setTime(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let minute = date.getMinutes();
        let second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
    } else {
        return "";
    }
};
//判断是否是电脑端
utils.isPC = function () {
    let userAgentInfo = navigator.userAgent;
    let Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
    let isPc = true;
    for (let i = 0;i< Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) > 0) {
            isPc = false;
            break;
        }
    }
    return isPc;
}
utils.isAndroid = function (){
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    return isAndroid
}
//获取url中的分享码
utils.getShareCode = function () {
    let url = window.location.href;
    let index = url.lastIndexOf("/");
    let code = url.substring(index + 1,url.length);
    return code
};
// 换算时间
utils.formatDateTime = function (timeStamp) {
    if (timeStamp == null) {
        return ""
    } else {
        let date = new Date();
        date.setTime(timeStamp);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return y + '-' + m + '-' + d;
    }

};
//srt时间转毫秒数
utils.srtToMs = function (srtTime) {
    let hhmmss = srtTime.split(",")[0];
    let SSS = srtTime.split(",")[1];
    let hour = parseInt(hhmmss.split(":")[0]);
    let min = parseInt(hhmmss.split(":")[1]);
    let sec = parseInt(hhmmss.split(":")[2]);
    return (hour * 60 * 60 + min * 60 + sec) * 1000 + parseInt(SSS);
};

// 换算时间
utils.getFileName = function (path){
    let pos1 = path.lastIndexOf('/');
    let pos2 = path.lastIndexOf('\\');
    let pos  = Math.max(pos1, pos2)
    if( pos < 0 ) {
        return path;
    } else {
        return path.substring(pos + 1);
    }
}
utils.splitFileName = function (text) {
    if (text.lastIndexOf(".") === -1) {
        return text
    } else {
        return text.substring(0, text.lastIndexOf("."))
    }
}
/**
 * 判断是否在同一天
 */
utils.isSameDay = function (startTime, endTime) {
    if(typeof startTime ==='string'){
        startTime = Number(startTime);
    }
    const startTimeMs = new Date(startTime).setHours(0,0,0,0);
    const endTimeMs = new Date(endTime).setHours(0,0,0,0);
    return startTimeMs === endTimeMs;
}

utils.isDefaultRecordName = function (name) {
    let array = name.split('_');
    if (array.length === 4) {
        if (array[0] === 'Vizard录屏'
            && /\d{8}/g.test(array[1])
            && /\d{2}/g.test(array[2])
            && /\d{2}/g.test(array[3])) {
            return true
        } else {
            return false
        }
    } else if (array.length === 3) {
        if (array[0] === 'Vizard'
            && /\d{8}/g.test(array[1])
            && /\d{6}/g.test(array[2])) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
//数字转汉字
//将数字（整数）转为汉字，从零到一亿亿，需要小数的可自行截取小数点后面的数字直接替换对应arr1的读法就行了
utils.convertToChinaNum = function (num) {
    let arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    let arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿');//可继续追加更高位转换值
    if(!num || isNaN(num)){
        return "零";
    }
    let english = num.toString().split("")
    let result = "";
    for (let i = 0; i < english.length; i++) {
        let des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        let arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
}
//根据长度切割文字
utils.spliceText = function (text, fontSize, width){
    let c = document.getElementById('myCanvas');
    let ctx = c.getContext("2d");
    let font = fontSize + 'px ' + 'Arial';
    ctx.font = font;
    if (text) {
        let nikeArr = Array.from(text);
        if (ctx.measureText(text).width >= width) {
            let pre = '';
            let last = '';
            for (let i = 0; i < nikeArr.length; i++) {
                let word = nikeArr.slice(i, i + 1)
                let savePre = pre
                pre = pre + word;
                if (ctx.measureText(pre).width > (width / 2 - 5) ) {
                    pre = savePre;
                    break;
                }
            }
            for (let i = nikeArr.length - 1; i > 0; i--) {
                let word = nikeArr.slice(i, i + 1)
                let saveLast = last
                last = word + last;
                if (ctx.measureText(last).width > (width / 2 - 5)) {
                    last = saveLast;
                    break;
                }
            }
            return pre + '...' + last
        } else {
            return text;
        }
    } else {
        return text;
    }
}
//移动端分享页截取视频标题
utils.spliceMobileRecordName = function (text, fontSize, width){
    let c = document.getElementById('myCanvas');
    let ctx = c.getContext("2d");
    let font = fontSize + 'px ' + 'Arial';
    ctx.font = font;
    if (text) {
        let nikeArr = Array.from(text);
        if (ctx.measureText(text).width > width) {
            let pre = '';
            for (let i = 0; i < nikeArr.length; i++) {
                let word = nikeArr.slice(i, i + 1)
                let savePre = pre
                pre = pre + word;
                if (ctx.measureText(pre).width > width - 12 -fontSize * 2) {
                    pre = savePre;
                    break;
                }
            }
            let last = text.slice(-2);
            return pre + '...' + last
        } else {
            return text;
        }
    } else {
        return text;
    }
}
utils.isNumber = function (val){
    let regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。
    if(regPos.test(val) ){
        return true;
    }else{
        return false;
    }
}
//毫秒数转srt时间
utils.msToSrt = function (value) {
    value = value / 1000;
    if(value == 0){
        return '00:00:00,000';
    } else {
        let h;
        if (value < 3600) {
            h = 0
        } else {
            h = parseInt(value / 3600);
        }
        let t = parseInt(value % 3600);
        let m = parseInt(t / 60);
        let s = parseInt(value % 60);
        let ms = parseInt((value * 1000).toFixed(1)) - parseInt(((h * 3600 + m * 60 + s) * 1000).toFixed(1));
        if (m < 10) {
            m = "0" + m;
        }
        if (h < 10) {
            h = "0" + h;
        }
        if (s < 10) {
            s = "0" + s;
        }
        if (ms < 10) {
            ms = '00' + ms
        } else if (ms < 100) {
            ms = '0' + ms
        }
        return h + ":" + m + ":" + s + ',' + ms;
    }
};
/**
 * 图片圆角设置
 * @param string x x轴位置
 * @param string y y轴位置
 * @param string w 图片宽
 * @param string y 图片高
 * @param string r 圆角值
 */
utils.handleBorderRect = function (ctx, x, y, w, h, r) {
    ctx.beginPath();
    // 左上角
    ctx.arc(x + r, y + r, r, Math.PI, 1.5 * Math.PI);
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.lineTo(x + w, y + r);
    // 右上角
    ctx.arc(x + w - r, y + r, r, 1.5 * Math.PI, 2 * Math.PI);
    ctx.lineTo(x + w, y + h - r);
    ctx.lineTo(x + w - r, y + h);
    // 右下角
    ctx.arc(x + w - r, y + h - r, r, 0, 0.5 * Math.PI);
    ctx.lineTo(x + r, y + h);
    ctx.lineTo(x, y + h - r);
    // 左下角
    ctx.arc(x + r, y + h - r, r, 0.5 * Math.PI, Math.PI);
    ctx.lineTo(x, y + r);
    ctx.lineTo(x + r, y);

    ctx.fill();
    ctx.closePath();
}
utils.dataURLtoBlob = function (dataUrl) {
    let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}
utils.getBrowserInfo = function(){
    let ua = navigator.userAgent.toLocaleLowerCase();
    let browserType = null;
    if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
        browserType = "IE";
    } else if (ua.match(/firefox/) != null) {
        browserType = "火狐";
    }else if (ua.match(/ubrowser/) != null) {
        browserType = "UC";
    }else if (ua.match(/opera/) != null) {
        browserType = "欧朋";
    } else if (ua.match(/bidubrowser/) != null) {
        browserType = "百度";
    }else if (ua.match(/metasr/) != null) {
        browserType = "搜狗";
    }else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        browserType = "QQ";
    }else if (ua.match(/maxthon/) != null) {
        browserType = "遨游";
    }else if (ua.match(/chrome/) != null) {
        browserType = "chrome";
    }else if (ua.match(/safari/) != null) {
        browserType = "Safari";
    }
    return browserType
};
//缩放显示图片
utils.scalePic = function (pic, width, height, isCenterCrop){
    if (pic.width <= width && pic.height <= height) {
        pic.domWidth = pic.width;
        pic.domHeight = pic.height;
    } else {
        if (pic.width / pic.height > width / height) {
            pic.domWidth = width;
            pic.domHeight = width / pic.width * pic.height
        } else {
            pic.domHeight = height;
            pic.domWidth = height / pic.height * pic.width
        }
    }
    if (isCenterCrop) {
        let scaleW  = width / pic.domWidth;
        let scaleH  = height / pic.domHeight;
        if (pic.domWidth > pic.domHeight) {
            if (pic.domWidth * scaleH >= width) {
                pic.domHeight = height;
                pic.domWidth = pic.domWidth * scaleH
            } else {
                pic.domHeight = pic.domHeight * scaleW;
                pic.domWidth = width
            }
        } else {
            if (pic.domHeight * scaleW >= height) {
                pic.domWidth = width;
                pic.domHeight = pic.domHeight * scaleW
            } else {
                pic.domHeight = height;
                pic.domWidth = pic.domWidth * scaleH;
            }
        }
    }
    return pic
}
// // 获取后缀名
utils.getFileExt = function(name){
    let index1 = name.lastIndexOf(".") + 1;
    let index2 = name.length;
    let ext = name.substring(index1, index2);
    return ext;
};
utils.limitFile = function (value){
    if (value.toLowerCase().endsWith('.mp3')
        || value.toLowerCase().endsWith('.mp4')
        || value.toLowerCase().endsWith('.wav')
        || value.toLowerCase().endsWith('.mov')
        || value.toLowerCase().endsWith('.3gp')
        || value.toLowerCase().endsWith('.wma')
        || value.toLowerCase().endsWith('.m4a')
        || value.toLowerCase().endsWith('.amr')
        || value.toLowerCase().endsWith('.avi')){
        return true
    } else {
        return false
    }
};
//srt时间转毫秒数
utils.srtToMs = function (srtTime) {
    let hhmmss = srtTime.split(",")[0];
    let SSS = srtTime.split(",")[1];
    let hour = parseInt(hhmmss.split(":")[0]);
    let min = parseInt(hhmmss.split(":")[1]);
    let sec = parseInt(hhmmss.split(":")[2]);
    return (hour * 60 * 60 + min * 60 + sec) * 1000 + parseInt(SSS);
};
// // 是否是音频
utils.isAudio = function(value){
    if (value.toLowerCase().endsWith('.mp3')
        || value.toLowerCase().endsWith('.wav')
        || value.toLowerCase().endsWith('.wma')
        || value.toLowerCase().endsWith('.m4a')
        || value.toLowerCase().endsWith('.amr')){
        return true
    } else {
        return false
    }
};
utils.exTractionLimitFile = function (value){
    if (value.toLowerCase().endsWith('.mp4')
        || value.toLowerCase().endsWith('.mov')
        || value.toLowerCase().endsWith('.3gp')
        || value.toLowerCase().endsWith('.avi')){
        return true
    } else {
        return false
    }
};
utils.isMac = function(){
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(isMac){
        return true
    } else {
        return false
    }
};
//验证手机号是否合法
utils.isPhoneNumber = function(phoneNum) {
    let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
    return reg.test(phoneNum);
};
//验证邮箱是否合法
utils.isEmail = function(value) {
    if (value.length < 64) {
        if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)){
            return true
        } else {
            return false
        }
    } else {
        return false
    }
};
//设置cookies
utils.setCookies = function (name, value, expires) {
    let config = {
        domain:process.env.VUE_APP_COOKIES_DOMAIN
    };
    if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
        //线上新网址使用新网址的域
        if (window.location.href.indexOf('vizard.video') !== -1) {
            config.domain = process.env.VUE_APP_NEW_COOKIES_DOMAIN
        }
    }
    if (expires) {
        config.expires = expires
    }
    Cookies.set(name, value, config);
};
utils.parseQueryString =  function (name) {
    let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    if(r!=null)
        return decodeURIComponent(r[2]);
    return null;
};
utils.isWx = function () {
    const ua = window.navigator.userAgent.toLowerCase()
    let matchArr = ua.match(/MicroMessenger/i)
    if (!(matchArr && (matchArr.toString() === 'micromessenger'))) {
        matchArr = ua.match(/Wechat/i)
        return matchArr && (matchArr.toString().toLowerCase() === 'wechat')
    } else {
        return true
    }
}
utils.Chs2Cht = function (fontId, text) {
    if (constants.CHS2CHT_FONTID.indexOf(Number(fontId)) !== -1) {
        return Chs2Cht(text);
    } else {
        return text
    }
};
utils.getCursorPosition = function (element) {
    let cursorPos = 0;
    if (document.selection) {//IE
        let selectRange = document.selection.createRange();
        selectRange.moveStart('character', -element.value.length);
        cursorPos = selectRange.text.length;
    } else if (element.selectionStart || element.selectionStart == '0') {
        cursorPos = element.selectionStart;
    }
    return cursorPos;
}
//设置光标位置
utils.setCaretPosition = function (ctrl, pos) {
    //设置光标位置函数
    if(ctrl.setSelectionRange) {
        ctrl.focus();  // 获取焦点
        ctrl.setSelectionRange(pos,pos);  // 设置选定区的开始和结束点
    } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();  // 创建选定区
        range.collapse(true);                // 设置为折叠,即光标起点和结束点重叠在一起
        range.moveEnd('character', pos);     // 移动结束点
        range.moveStart('character', pos);   // 移动开始点
        range.select();                      // 选定当前区域
    }
    console.log(ctrl)
}
//设置可编辑div光标位置
utils.setCaretPositionDiv = (element, pos) => {
    let range, selection;
    if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
    {
        range = document.createRange();//创建一个选中区域
        // range.selectNodeContents(element);//选中节点的内容
        if(element.innerHTML.length > 0) {
            range.setStart(element.childNodes[0], pos); //设置光标起始为指定位置
            range.setEnd(element.childNodes[0], pos); //设置光标起始为指定位置
        }
        range.collapse(true);       //设置选中区域为一个点
        selection = window.getSelection();//获取当前选中区域
        selection.removeAllRanges();//移出所有的选中范围
        selection.addRange(range);//添加新建的范围
    }
    else if (document.selection)//IE 8 and lower
    {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(element);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
    }
}
utils.searchSubStr = function (str, subStr){
    let positions = [];
    let pos = str.indexOf(subStr);
    while(pos > -1){
        positions.push(pos);
        pos = str.indexOf(subStr, pos + 1);
    }
    return positions
}
utils.computeTextWidth = function (text, fontSize){
    let c = document.getElementById('myCanvas');
    let ctx = c.getContext("2d");
    let font = fontSize + 'px ' + 'Arial';
    ctx.font = font;
    return ctx.measureText(text).width
}
//文件大小
utils.changeFileLength = function(fileByte) {
    if (fileByte == '--') {
        return '--'
    } else {
        let fileSizeByte = fileByte;
        let fileSizeMsg = "";
        if (fileSizeByte < 1048576) fileSizeMsg = Number((fileSizeByte / 1024).toFixed(2)) + "KB";
        else if (fileSizeByte == 1048576) fileSizeMsg = "1MB";
        else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) fileSizeMsg = Number((fileSizeByte / (1024 * 1024)).toFixed(2)) + "MB";
        else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824) fileSizeMsg = "1GB";
        else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) fileSizeMsg = Number((fileSizeByte / (1024 * 1024 * 1024)).toFixed(2)) + "GB";
        else fileSizeMsg = "文件超过1TB";
        return fileSizeMsg;
    }
};
//根据环境判断是否发送log
utils.log = function (){
    if (process.env.VUE_APP_ENVIRONMENT === 'test' || localStorage.getItem(constants.VIZARD_OPEN_LOG)) {
        let log = 'vizard-log:';
        for (let i = 0; i < arguments.length; i++) {
            log = log + ' ' + arguments[i];
        }
        console.log(log);
    }
}
//是否是全屏
utils.checkIsFullScreen = function () {
    let isFullScreen = document.fullscreen || document.mozFullScreen || document.webkitIsFullScreen;
    return isFullScreen == undefined ? false : isFullScreen;
}
//开启全屏
utils.fullScreen = function (ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
    }
}
//关闭全屏
utils.exitFullscreen = function () {
    let ele = document;
    if (ele.exitFullscreen) {
        ele.exitFullscreen();
    } else if (ele.mozCancelFullScreen) {
        ele.mozCancelFullScreen();
    } else if (ele.webkitCancelFullScreen) {
        ele.webkitCancelFullScreen();
    }
}
//获取当前tab模式
utils.getTypeDisplayModel = () => {
    if (localStorage.getItem(constants.VIZARD_SAVE_DISPLAY_MODEL_NAME)) {
        return Number(localStorage.getItem(constants.VIZARD_SAVE_DISPLAY_MODEL_NAME));
    } else {
        return constants.DISPLAY_MODEL_ITEM
    }
}
//设置当前tab模式
utils.setTypeDisplayModel = (value) => {
    localStorage.setItem(constants.VIZARD_SAVE_DISPLAY_MODEL_NAME, value);
}
//截取图片
utils.getImagePortion = (imgSrc, newWidth, newHeight, startX, startY, ratio) => {
    let tnCanvas = document.createElement('canvas');
    let tnCanvasContext = tnCanvas.getContext('2d');
    tnCanvas.width = newWidth; tnCanvas.height = newHeight;
    let imgObj = new Image();
    imgObj.src = imgSrc;
    imgObj.crossOrigin = 'Anonymous';
    return new Promise((resolve, reject) => {
        imgObj.onload = function () {
            let bufferCanvas = document.createElement('canvas');
            let bufferContext = bufferCanvas.getContext('2d');
            bufferCanvas.width = imgObj.width;
            bufferCanvas.height = imgObj.height;
            bufferContext.drawImage(imgObj, 0, 0);
            tnCanvasContext.drawImage(bufferCanvas, startX, startY, newWidth * ratio, newHeight * ratio, 0, 0, newWidth, newHeight);
            resolve(tnCanvas.toDataURL())
        }
        imgObj.onerror = function () {
            reject('error')
        }
    })
}
//360浏览器（极速内核）
utils.check360 = (value) => {
    for (let key in value.plugins) {
        // np-mswmp.dll只在360浏览器下存在
        if (value.plugins[key].filename == 'np-mswmp.dll') {
            return true;
        }
    }
    return false;
}
//获取浏览器版本信息
utils.getBrowser = () => {
    var UserAgent = navigator.userAgent.toLowerCase();
    var browserInfo = {};
    var browserArray = {
        IE: window.ActiveXObject || "ActiveXObject" in window,
        // IE
        Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1,
        // Chrome浏览器
        Firefox: UserAgent.indexOf('firefox') > -1,
        // 火狐浏览器
        Opera: UserAgent.indexOf('ope') > -1,
        // Opera浏览器
        Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') == -1,
        // safari浏览器
        Edge: UserAgent.indexOf('edg') > -1,
        // Edge浏览器
        QQBrowser: /qqbrowser/.test(UserAgent),
        // qq浏览器
        WeixinBrowser: /MicroMessenger/i.test(UserAgent),
        // 微信浏览器
        Sogou: UserAgent.indexOf("se 2.x") > -1,
        // 搜狗浏览器
    };
    // console.log(browserArray)
    for (var i in browserArray) {
        if (browserArray[i]) {
            var versions = '';
            if (i == 'IE') {
                versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2];
            } else if (i == 'Chrome') {
                for (var mt in navigator.mimeTypes) {

                    //检测是否是360浏览器(测试只有pc端的360才起作用)
                    if (navigator.mimeTypes[mt]['type'] == 'application/360softmgrplugin') {
                        i = '360';
                    }
                    if (utils.check360(navigator)) {
                        i = '360';
                    }
                }
                versions = UserAgent.match(/chrome\/([\d.]+)/)[1];
            } else if (i == 'Firefox') {
                versions = UserAgent.match(/firefox\/([\d.]+)/)[1];
            } else if (i == 'Ope') {
                versions = UserAgent.match(/ope\/([\d.]+)/)[1];
            } else if (i == 'Safari') {
                versions = UserAgent.match(/version\/([\d.]+)/)[1];
            } else if (i == 'Edg') {
                versions = UserAgent.match(/edg\/([\d.]+)/)[1];
            } else if (i == 'QQBrowser') {
                versions = UserAgent.match(/qqbrowser\/([\d.]+)/)[1];
            }
            browserInfo.type = i; browserInfo.versions = parseInt(versions);
        }
    } return browserInfo;
}
//获取加载时间
utils.getPerformanceTiming = function () {
    var performance = window.performance;

    if (!performance) {
        // 当前浏览器不支持
        console.log('你的浏览器不支持 performance 接口');
        return false;
    }

    var t = performance.timing;
    var times = {};

    //【重要】页面加载完成的时间
    //【原因】这几乎代表了用户等待页面可用的时间
    times.loadPage = t.loadEventEnd - t.navigationStart;
    return times;
}
//设置头像信息
utils.setUserAvatar = (userInfo) => {
    let headText = '';
    if (!userInfo.nickname) {
        if (userInfo.wxNickname) {
            userInfo.nickname = userInfo.wxNickname;
        } else if (userInfo.loginMobile) {
            userInfo.nickname = userInfo.wxNickname;
        } else if (userInfo.email) {
            userInfo.nickname = userInfo.email;
        }
    }
    if (userInfo.nickname) {
        headText = userInfo.nickname.substring(userInfo.nickname.length - 1, userInfo.nickname.length);
    }
    userInfo.headText = headText;
    userInfo.backgroundColor = utils.getHeaderBackgroundColor(userInfo);
    return userInfo
}
//复制文字到剪切板
utils.handleCopyClipboard = (content) => {
    const inputDom = document.createElement('input');
    inputDom.setAttribute('value', content);
    document.body.appendChild(inputDom);
    inputDom.select();
    document.execCommand('copy');
    document.body.removeChild(inputDom);
}
//获取裁剪后跳转时间
utils.getSeekTime = (currentTime, clipInfoPart) => {
    let seekTime = undefined;
    clipInfoPart.forEach((item) => {
        if (item.bt <= currentTime && item.et > currentTime) {
            seekTime = item.et;
            return
        }
    })
    return seekTime
}
//获取进度条相对于原视频的时间
utils.getSilderSeekTime = (sliderTime, clipInfoPart) => {
    let seekTime = sliderTime
    let cutTime = 0
    if (clipInfoPart.length !== 0) {
        for (let i = 0; i < clipInfoPart.length; i++) {
            if (i === 0) {
                cutTime = 0 + clipInfoPart[i].bt;
            } else {
                cutTime = cutTime + (clipInfoPart[i].bt - clipInfoPart[i - 1].et);
            }
            if (cutTime >= sliderTime) {
                if (i !== 0) {
                    let sumTime = 0;
                    clipInfoPart.forEach((item, index) => {
                        if (index < i) {
                            sumTime = sumTime + (item.et - item.bt)
                        }
                    })
                    seekTime = seekTime + sumTime;
                }
                break
            } else {
                if (i === clipInfoPart.length - 1) {
                    let sumTime = 0;
                    clipInfoPart.forEach((item) => {
                        sumTime = sumTime + (item.et - item.bt)
                    })
                    seekTime = seekTime + sumTime;
                }
            }
        }
    }
    return seekTime
}
//获取摄像头的border信息
utils.changeCameraShape = (shape) => {
    if (shape === constants.CAMERA_SHAPE_CIRCLE) {
        return '50%'
    } else if (shape === constants.CAMERA_SHAPE_SQUARE || shape === constants.CAMERA_SHAPE_RECTANGLE) {
        return '6%'
    }
}
//判断是否是功能性按键
utils.isFunctionKey = (e) => {
    if (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 12 || e.keyCode === 13 || e.keyCode === 16 || e.keyCode === 17 || e.keyCode === 18
        || e.keyCode === 20 || e.keyCode === 27 || e.keyCode === 32 || e.keyCode === 33 || e.keyCode === 34 || e.keyCode === 35 || e.metaKey) {
        return true
    }
    return false;
}
//计算播放尺寸
utils.computeVideoSize = function (areaWidth, areaHeight, videoWidth, videoHeight) {
    let width, height;
    let areaRatio = areaWidth / areaHeight;
    let videoRatio = videoWidth / videoHeight;
    if (videoRatio > areaRatio) {
        width = areaWidth;
        height = areaWidth / videoRatio;
    } else if (videoRatio < areaRatio) {
        height = areaHeight;
        width = areaHeight * videoRatio;
    } else {
        height = areaHeight;
        width = areaWidth;
    }
    if (height > areaHeight) {
        height = areaHeight;
        width = areaHeight * videoRatio
    }
    return {width, height}
}
//比较两个数组是否有相同项，将相同项存入新数组
utils.getArrayInclude = function (arr1, arr2) {
    let temp = []
    for (const item of arr2) {
        arr1.indexOf(item) !== -1 ? temp.push(item) : ''
    }
    return temp
}
//数组对象去重
utils.uniqueFunc = function (arr, uniId) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}
// 摄像头形状是否是圆形
utils.isCircleToCamera = function(shape) {
    return shape === constants.CAMERA_SHAPE_CIRCLE;
}
//获取图片宽高信息
utils.getImgInfo = function (imgUrl) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = "Anonymous"; //注意存放顺序
        let obj = {}
        // 图片加载成功
        img.onload = function () {
            obj = {
                width: this.width,
                height: this.height,
            }
            resolve(obj)
        }
        // 图片加载失败
        img.onerror = function() {
            reject()
            console.log('img load error')
        };
        img.src = imgUrl
    })
};
//计算导出背景图的宽高
utils.getImageWidthAndHeight = function (imageWidth, imageHeight, backgroundWidth, backgroundHeight) {
    let width = 0;
    let height = 0;
    if (imageWidth <= backgroundWidth && imageHeight <= backgroundHeight) {
        width = imageWidth;
        height = imageHeight;
    } else {
        if (imageWidth / imageHeight > backgroundWidth / backgroundHeight) {
            width = backgroundWidth;
            height = backgroundWidth / imageWidth * imageHeight;
        } else {
            height = backgroundHeight;
            width = backgroundHeight / imageHeight * imageWidth;
        }
    }
    let scaleW  = backgroundWidth / width;
    let scaleH  = backgroundHeight / height;
    if (width > height) {
        if (width * scaleH >= backgroundWidth) {
            height = backgroundHeight;
            width = Math.trunc(width * scaleH);
        } else {
            height = Math.trunc(height * scaleW);
            width = backgroundWidth;
        }
    } else {
        if (height * scaleW >= backgroundHeight) {
            width = backgroundWidth;
            height = Math.trunc(height * scaleW);
        } else {
            height = backgroundHeight;
            width = Math.trunc(width * scaleH);
        }
    }
    return {width, height};
};
// 颜色 rgb 转 16 进制
utils.colorRgbToHex = function(rgbColor) {
    if (rgbColor) {
        // RGB颜色值的正则
        let reg = /^(rgb|RGB)/;
        if (reg.test(rgbColor)) {
            let rgb = rgbColor.split(',');
            let r = parseInt(rgb[0].split('(')[1]);
            let g = parseInt(rgb[1]);
            let b = parseInt(rgb[2].split(')')[0]);
            let hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            return hex;
        } else {
            return String(rgbColor);
        }
    }
}
/**
 * 通过视频裁剪信息数组，来获取裁剪后的视频时长
 * @param {Array} clipInfoArr 数据结构为 [{bt:Number, et:Number}]
 */
utils.getVideoDurationByClipInfo = function(clipInfoArr) {
    let videoDuration = 0;
    clipInfoArr.forEach(item => {
        videoDuration += item.et - item.bt;
    })
    return videoDuration;
}
/**
 * 通过视频裁剪信息数组，来获取裁剪掉的切片数组
 * @param {Array} clipInfoArr 数据结构为 [{bt:Number, et:Number}]
 * @param {Number} videoDuration 视频时长
 */
utils.getDeleteClipByClipInfo = function(clipInfoArr, videoDuration) {
    let deletedClipArr = [];
    let clipInfoArrLen = clipInfoArr.length;
    for (let i = 0; i < clipInfoArrLen; i++) {
        if (i === 0 && clipInfoArr[i].bt !== 0) {
            deletedClipArr.push({bt: 0, et: clipInfoArr[i].bt});
        }
        if (clipInfoArr[i + 1] && clipInfoArr[i].et !== clipInfoArr[i + 1].bt) {
            deletedClipArr.push({bt: clipInfoArr[i].et, et: clipInfoArr[i + 1].bt});
        }
        if (i === clipInfoArrLen - 1 && clipInfoArr[i].et !== videoDuration) {
            deletedClipArr.push({bt: clipInfoArr[i].et, et: videoDuration});
        }
    }
    return deletedClipArr;
}
//判断视频是否为默认名(模糊判断 去除特殊字符后 是否Vizard录屏+数字)
utils.isDefaultVideoName = (name) => {
    let isDefaultRules = false
    let regNum = /^[0-9]*$/;
    name = name.replace(/([`_~!@#$^&*()=|{}':;',\\[\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ])/g, "")
    if (name.includes('Vizard录屏') && regNum.test(name.replace("Vizard录屏", ""))) {
        isDefaultRules = true;
    }
    return !isDefaultRules
}
// byte 转为 Gb (保留两位小数点)
utils.byteToGb = (byte) => {
    if (byte === 0) return 0;
    let gbNum = Number((byte / (1024 ** 3)).toFixed(2));
    if (gbNum === 0) {
        gbNum = 0.01;
    }
    return gbNum;
}
export default utils;
