const routers = [
    {
        path: '/',
        name: 'index',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/Vizard.vue'], resolve)
    },
    {
        path: '/work',
        name: 'work',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardWork.vue'], resolve)
    },
    {
        path: '/us',
        name: 'us',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardAboutUs.vue'], resolve)
    },
    {
        path: '/download',
        name: 'download',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardDownLoad.vue'], resolve)
    },
    {
        path: '/download-old',
        name: 'download-old',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardDownLoadOld.vue'], resolve)
    },
    {
        path: '/aliRecord',
        name: 'aliRecord',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/vizardAliStream.vue'], resolve)
    },
    {
        path: '/help',
        name: 'question',
        meta: {
            title: '',
            showDetail: false
        },
        component: (resolve) => require(['./questionComponents/VizardQuestion.vue'], resolve),
        children:[{
            path: ':category/:id?',
            name: 'questionCategory',
            meta: {
                title: '',
                showDetail: true
            },
            component: (resolve) => require(['./questionComponents/VizardQuestionCategory'], resolve),
        }],
    },
    {
        path: '/jumpWX',
        name: 'jumpWX',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardLoginWXJumpPage.vue'], resolve)
    },
    {
        path: '/course/unblock',
        name: 'unblock',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./course/unblock.vue'], resolve)
    },
    {
        path: '/s',
        name: 's',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardShareIntermediatePage.vue'], resolve)
    },
    {
        path: '/bindWx',
        name: 'bindWx',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardBindWxPage.vue'], resolve)
    },
    {
        path: '/shareLogin',
        name: 'shareLogin',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/vizardShareLogin'], resolve)
    },
    {
        path: '/mobileShare',
        name: 'mobileShare',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardMobileMiddlePage'], resolve)
    },
    {
        path: '/mobileVideoLibraryShare',
        name: 'mobileVideoLibraryShare',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardMobileVideoLibraryPage'], resolve)
    },
    {
        path: '/detail',
        name: 'detail',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./editorComponents/VizardRecordDetails'], resolve)
    },
    {
        path: '/record',
        name: 'record',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardRecord'], resolve)
    },
    {
        path: '/upload',
        name: 'upload',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardUpload'], resolve)
    },
    {
        path: '/FFmpegTest',
        name: 'FFmpegTest',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./editorComponents/FFmpegTest'], resolve)
    },
    {
        path: '/news',
        name: 'news',
        meta: {
            title: '',
            showfather: true
        },
        component: (resolve) => require(['./components/VizardNewsPage'], resolve),
        children:[{
            path: 'detail/:id',
            name: 'newsDetail',
            meta: {
                title: '',
                showfather: false
            },
            component: (resolve) => require(['./components/VizardNewsDetail'], resolve)
        }],
    },
    {
        path: '/record-intro',
        name: 'recordIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/screenRecord'], resolve)
    },
    {
        path: '/asr-intro',
        name: 'asrIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/asr'], resolve)
    },
    {
        path: '/clip-intro',
        name: 'clipIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/clip'], resolve)
    },
    {
        path: '/share-intro',
        name: 'shareIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/share'], resolve)
    },
    {
        path: '/library-intro',
        name: 'libraryIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/library'], resolve)
    },
    {
        path: '/staff-scene',
        name: 'staffScene',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./scene/staff'], resolve)
    },
    {
        path: '/notes-intro',
        name: 'notesIntro',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./productIntro/notes'], resolve)
    },
    {
        path: '/teacher-scene',
        name: 'teacherScene',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./scene/teacher'], resolve)
    },
    {
        path: '/student-scene',
        name: 'studentScene',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./scene/student'], resolve)
    },
    {
        path: '/finalDetail',
        name: 'finalDetail',
        meta: {
            title: ''
        },
        component: (resolve) => require(['./components/VizardFinalDetail'], resolve)
    },

];
export default routers;
